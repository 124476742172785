import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const getPatientSuccess: SessionMockSchema = {
  operation: sessionOperations.GetPatient,
  title: 'Success',
  name: 'GetPatient',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      patientAlias: {
        tenantId: '',
        systemId: '',
        externalId: '12345',
      },
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      patient: {
        externalAlias: {
          tenantId: '',
          systemId: '',
          externalType: 'patient:id',
          externalId: '12345',
        },
        addressLine1: '1234 Margeret Drive',
        addressLine2: '',
        addressZip: '92110',
        addressCity: 'San Diego',
        addressState: 'CA',
        cellPhone: '805-243-3422',
        homePhone: '805-243-2342',
        workPhone: '805-243-2645',
        email: 'clintdeck@gmail.com',
        preferredPronouns: 'PRONOUNS_OTHER',
        emergencyContactName: 'Bill Black',
        emergencyContactPhone: '916-123-5342',
        preferredName: 'Clint',
        firstName: 'Clint',
        middleName: '',
        lastName: 'Decker',
        insurance: {
          externalAlias: {
            tenantId: '',
            systemId: '',
            externalType: 'insurance:id',
            externalId: '123',
          },
          insuranceType: 'INSURANCE_TYPE_PRIMARY',
          insuranceName: 'General Coverage',
          policyId: '434',
          groupId: '255',
          policyHolderName: 'Clay Decker',
          relationshipToPolicyHolder: 'Father',
          copay: [
            { copayType: '2', copayAmount: '500' },
            { copayType: '1', copayAmount: '50' },
          ],
        },
        dob: '1952-05-15T00:00:00Z',
        sex: 'SEX_MALE',
        locations: [
          {
            externalAlias: {
              tenantId: '',
              systemId: '',
              externalType: 'department:id',
              externalId: '195',
            },
          },
        ],
      },
    },
    null,
    2
  ),
}
