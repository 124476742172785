import { OperationMethod } from 'types/sessions'

export interface SessionMockSchema {
  operation: string
  // title is the display name of the operation (e.g. Success List Slots)
  title: string
  // name is the actual operation name (e.g. ListSlots)
  name: string
  responseMethod: OperationMethod
  responseCode: number
  request: string
  response: string
}

export enum sessionOperations {
  GetPatient = 'Get Patient',
  ListSlots = 'List Slots',
  ScheduleAppointment = 'Schedule Appointment',
  RescheduleAppointment = 'Reschedule Appointment',
  CancelAppointment = 'Cancel Appointment',
  ConfirmAppointment = 'Confirm Appointment',
  CheckinAppointment = 'Checkin Appointment',
  GetPatientPhoto = 'Get Patient Photo',
  GetPatientInsuranceCardPhoto = 'GetPatientInsuranceCardPhoto',
  ListPatientEncountersAsync = 'ListPatientEncountersAsync',
  ListPatientOrdersAsync = 'ListPatientOrdersAsync',
  ListPatientDiagnosticReports = 'ListPatientDiagnosticReports',
  GetPatientDiagnosticReport = 'GetPatientDiagnosticReport',
  GetOrder = 'GetOrder',
  ListLocations = 'ListLocations',
  ListProviders = 'ListProviders',
  GetVisitSummary = 'GetVisitSummary',
  SubmitForm = 'SubmitForm',
}
