import { CancelAppointmentOperations } from './cancelAppointment/operations'
import { CheckinAppointmentOperations } from './checkinAppointment/operations'
import { ConfirmAppointmentOperations } from './confirmAppointment/operations'
import { GetPatientOperations } from './getPatient/operations'
import { GetPatientInsuranceCardPhotoOperations } from './getPatientInsuranceCardPhoto/operations'
import { GetPatientPhotoOperations } from './getPatientPhoto/operations'
import { ListPatientEncountersAsyncOperations } from './listPatientEncountersAsync/operations'
import { ListSlotsOperations } from './listSlots/operations'
import { RescheduleAppointmentOperations } from './rescheduleAppointment/operations'
import { ScheduleAppointmentOperations } from './scheduleAppointment/operations'
import { SessionMockSchema, sessionOperations } from './sessionUtils'
import { ListPatientOrdersAsync } from './listPatientOrdersAsync/operation'
import { ListPatientDiagnosticReports } from './listPatientDiagnosticReports/operations'
import { GetPatientDiagnosticReport } from './getPatientDiagnosticReport/operations'
import { ListLocations } from './listLocations/operations'
import { ListProviders } from './listProviders/operations'
import { GetOrder } from './getOrder/operations'
import { GetVisitSummary } from './getVisitSummary/operations'
import { SubmitForm } from './submitForm/operations'

export const sessionOperationMocks = new Map<
  sessionOperations,
  SessionMockSchema[]
>([
  [sessionOperations.GetPatient, GetPatientOperations],
  [sessionOperations.ListSlots, ListSlotsOperations],
  [sessionOperations.ScheduleAppointment, ScheduleAppointmentOperations],
  [sessionOperations.RescheduleAppointment, RescheduleAppointmentOperations],
  [sessionOperations.CancelAppointment, CancelAppointmentOperations],
  [sessionOperations.ConfirmAppointment, ConfirmAppointmentOperations],
  [sessionOperations.CheckinAppointment, CheckinAppointmentOperations],
  [sessionOperations.GetPatientPhoto, GetPatientPhotoOperations],
  [
    sessionOperations.GetPatientInsuranceCardPhoto,
    GetPatientInsuranceCardPhotoOperations,
  ],
  [
    sessionOperations.ListPatientEncountersAsync,
    ListPatientEncountersAsyncOperations,
  ],
  [sessionOperations.ListPatientOrdersAsync, ListPatientOrdersAsync],
  [
    sessionOperations.ListPatientDiagnosticReports,
    ListPatientDiagnosticReports,
  ],
  [sessionOperations.GetPatientDiagnosticReport, GetPatientDiagnosticReport],
  [sessionOperations.GetOrder, GetOrder],
  [sessionOperations.ListLocations, ListLocations],
  [sessionOperations.ListProviders, ListProviders],
  [sessionOperations.GetVisitSummary, GetVisitSummary],
  [sessionOperations.SubmitForm, SubmitForm],
])
