import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const scheduleAppointmentSuccess: SessionMockSchema = {
  operation: sessionOperations.ScheduleAppointment,
  title: 'Success',
  name: 'ScheduleAppointment',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      slotAlias: {
        tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
        systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
        externalType: 'appointment:id',
        externalId: '3',
      },
      patientAlias: {
        tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
        systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
        externalType: 'patient:id',
        externalId: '1',
      },
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      appointment: {
        externalAlias: {
          tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
          systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
          externalType: 'appointment:id',
          externalId: '1202264',
        },
        status: 'Booked',
        appointmentType: 'PHYSICAL EXAM',
        slot: {
          externalAlias: {
            tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
            systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
            externalType: 'appointment:id',
            externalId: '1202264',
          },
          startTime: '2022-03-20T16:00:00Z',
          endTime: '2022-03-20T16:30:00Z',
          provider: {
            externalAlias: {
              tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
              systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
              externalType: 'provider:id',
              externalId: '1',
            },
          },
          location: {
            externalAlias: {
              tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
              systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
              externalType: 'department:id',
              externalId: '1',
            },
          },
        },
        patient: {
          externalAlias: {
            tenantId: '486701a1-7f0c-43f7-bee6-08f4e9302ee9',
            systemId: 'a205f371-8711-4f12-9469-1233dc9f195f',
            externalType: 'patient:id',
            externalId: '35880',
          },
        },
        appointmentTypeId: '4',
      },
    },
    null,
    2
  ),
}
