import { combineReducers } from 'redux'
import modalReducer, { initialState as modalInitialState } from './modalReducer'
import resourceReducer, {
  initialState as resourceInitialState,
} from './resourceReducer'
import credentialsReducer, {
  initialState as credsInitialState,
} from './credentialsReducer'
import sessionReducer, {
  initialState as sessionInitialState,
} from './sessionReducer'
import { athenaAPI } from '../service/ServiceApi'

export const initialState = {
  resources: resourceInitialState,
  modalVisibility: modalInitialState,
  sessions: sessionInitialState,
  credentials: credsInitialState,
}

export const rootReducer = combineReducers({
  resources: resourceReducer,
  modalVisibility: modalReducer,
  credentials: credentialsReducer,
  [athenaAPI.reducerPath]: athenaAPI.reducer,
  sessions: sessionReducer,
})
