// @todo: Write tests -- seriously **Jeff Hudzik**

type TokenRetriever = () => Promise<string>

type TokenSuccess = (token: string) => void

class TokenManager {
  private static _instance: TokenManager
  private _getAccessToken!: TokenRetriever
  private _onSuccess: TokenSuccess | undefined

  public static getInstance(): TokenManager {
    if (!TokenManager._instance) {
      TokenManager._instance = new TokenManager()
    }
    return TokenManager._instance
  }

  public set getAccessToken(tr: TokenRetriever) {
    this._getAccessToken = tr
  }
  public get getAccessToken(): TokenRetriever {
    // @todo: Can we return rejected Promise if _getAccessToken is undefined
    return this._getAccessToken
  }

  public async getCurrentAccessToken(): Promise<string> {
    const token = await this._getAccessToken()

    if (this._onSuccess) {
      this._onSuccess(token)
    }

    return token
  }

  public set onSuccess(ts: TokenSuccess) {
    this._onSuccess = ts
  }
}

export default TokenManager
