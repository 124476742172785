import { Button } from 'tendo-ui'
import { ColumnsType } from 'antd/lib/table'
import { ResourceType } from 'types/types'
import * as Styled from '../Styles'
import axios, { AxiosRequestConfig } from 'axios'
import { useCallback, useState } from 'react'
import { TableRowSelection } from 'antd/lib/table/interface'
import ResourceTable from './ResourceTable'

const deleteUrl = '/kyruus/v9/batch/delete'

function DeleteTableIDs(
  resourceType: string,
  ids: string[],
  onFinish?: () => void
) {
  const requestConfig: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  const data = {
    resourcetype: resourceType,
    resourceids: ids,
  }

  axios
    .put(deleteUrl, data, requestConfig)
    .then(() => {
      if (onFinish) {
        onFinish()
      }
    })
    .catch(() => console.log('request failed'))
}

interface KyruusResourceTableProps {
  title: string
  resourceType: string
  loading?: boolean
  dataSource: ResourceType[]
  columns: ColumnsType<ResourceType>
  footer?(): React.ReactNode
  footerButtons?(): React.ReactNode
  refreshResource?(): void
  idSelector(data: ResourceType): string
}

// KyruusResourceTable is a wrapper on ResourceTable that adds the ability to select and delete resources
const KyruusResourceTable = (props: KyruusResourceTableProps) => {
  const [selectedIDs, setSelectedIDs] = useState<string[]>([])
  const { footerButtons, resourceType, refreshResource } = props

  const rowSelection: TableRowSelection<ResourceType> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ResourceType[]) => {
      const rowIds: string[] = selectedRows.map((row) => props.idSelector(row))
      setSelectedIDs(rowIds)
    },
    type: 'checkbox',
  }

  const onClickDeleteIDs = useCallback(
    () => DeleteTableIDs(resourceType, selectedIDs, refreshResource),
    [resourceType, selectedIDs, refreshResource]
  )

  const memoFooterButtons = useCallback(
    () => (
      <>
        {footerButtons ? footerButtons() : ''}
        <Styled.ButtonWrapper>
          <Button key="1" onClick={onClickDeleteIDs}>
            Delete Selected Items
          </Button>
        </Styled.ButtonWrapper>
      </>
    ),
    [footerButtons, onClickDeleteIDs]
  )

  return (
    <ResourceTable
      title={props.title}
      dataSource={props.dataSource}
      columns={props.columns}
      loading={props.loading}
      rowSelection={rowSelection}
      footer={props.footer}
      footerButtons={memoFooterButtons}
      rowKeySelector={props.idSelector}
      refreshResource={props.refreshResource}
    />
  )
}

export default KyruusResourceTable
