import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const cancelAppointmentSuccess: SessionMockSchema = {
  operation: sessionOperations.CancelAppointment,
  title: 'Success',
  name: 'CancelAppointment',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: 'eb234666-98e5-4409-828e-243e8961cf59',
        systemId: '37d609b0-16cf-420c-97b0-dce3a188e024',
        externalType: 'appointment:id',
        externalId: '456',
      },
      patientAlias: {
        tenantId: 'eb234666-98e5-4409-828e-243e8961cf59',
        systemId: '37d609b0-16cf-420c-97b0-dce3a188e024',
        externalType: 'patient:id',
        externalId: '1',
      },
      reason: "Couldn't go",
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: 'eb234666-98e5-4409-828e-243e8961cf59',
        systemId: '37d609b0-16cf-420c-97b0-dce3a188e024',
        externalType: 'appointment:id',
        externalId: '456',
      },
    },
    null,
    2
  ),
}
