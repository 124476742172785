import { notification } from 'antd'
import { useEffect, useState } from 'react'
import { Title, Select, SelectOption, Input, Button } from 'tendo-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  AddOperationToSession,
  AddSessionOperationRequest,
} from 'service/SessionService'
import { toggleModalVisibility } from 'store/modalReducer'
import { OperationMethod, OperationMethodList } from 'types/sessions'
import * as Styled from '../../Styles'
import { AddItemToSessionStateWithDispatch } from 'store/sessionReducer'
import SessionOperationTemplatesModal from './SessionOperationTemplatesModal'

export interface AddOperationModalProps {
  destroyOnClose?: boolean
}

const AddOperationModal = (props: AddOperationModalProps) => {
  const [operationName, setOperationName] = useState<string>('')
  const [operationMethod, setOperationMethod] =
    useState<OperationMethod>('POST')
  const [request, setRequest] = useState<string>('')
  const [response, setResponse] = useState<string>('')
  const [responseCode, setResponseCode] = useState<number>(200)
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const sessionID = useAppSelector((state) => state.sessions.sessionID)
  const visible = useAppSelector(
    (state) => state.modalVisibility.addSessionOperationVisible
  )

  useEffect(() => {
    setOperationName('')
    setOperationMethod('POST')
    setResponse('')
    setRequest('')
    setResponseCode(200)
  }, [visible])

  const onClose = () => {
    dispatch(toggleModalVisibility('addSessionOperationVisible'))
  }

  const onFailAddOperation = () => {
    notification['error']({
      message: 'Error adding Operation to Session',
      description: `Unable to add ${operationName} to sessionID: ${sessionID}`,
    })
  }

  const onAdd = () => {
    const addRequest: AddSessionOperationRequest = {
      sessionID,
      operationName,
      operation: {
        request,
        method: operationMethod,
        // These two are default empty for now
        requestQuery: '',
        requestHeaders: new Map<string, string[]>(),
        // Response details
        response,
        responseCode,
        // DEFAULTED
        responseContentType: 'application/json',
        // Default empty for now
        responseHeaders: new Map<string, string[]>(),
      },
    }

    AddOperationToSession(
      addRequest,
      (i: string) => {
        setIsButtonLoading(true)
        AddItemToSessionStateWithDispatch(dispatch)(i, addRequest)
        setIsButtonLoading(false)
        onClose()
      },
      onFailAddOperation
    )
  }

  const onChangeName = (name: string) => {
    // Don't allow special characters
    const specialCharRegex = new RegExp(/^[a-zA-Z0-9]*$/)
    if (specialCharRegex.test(name)) {
      setOperationName(name)
    }
  }

  return (
    <Styled.LargeModal
      open={visible}
      closable={true}
      onCancel={onClose}
      okText={'Add'}
      cancelButtonProps={{ shape: 'round' }}
      okButtonProps={{
        disabled: operationName === '',
        shape: 'round',
        loading: isButtonLoading,
      }}
      onOk={onAdd}
      {...props}
    >
      <Styled.Content>
        <Title level={1} variant={'headline'}>
          Add Operation to Session
        </Title>
      </Styled.Content>
      <div>
        <Styled.RowWrapper>
          <Styled.SmallInputWrapper style={{ width: '60%' }}>
            <Styled.InputLabelText>Operation</Styled.InputLabelText>
            <Input
              value={operationName}
              type={'text'}
              onChange={(e) => onChangeName(e.currentTarget.value)}
              width={'100%'}
            />
          </Styled.SmallInputWrapper>
          <Styled.RowInputWrapper>
            <Styled.InputLabelText>Response Method</Styled.InputLabelText>
            <Select
              width="100%"
              value={operationMethod}
              onChange={(val) => setOperationMethod(val as OperationMethod)}
            >
              {OperationMethodList.map((method, idx) => (
                <SelectOption key={method + idx.toString()} value={method}>
                  {method}
                </SelectOption>
              ))}
            </Select>
          </Styled.RowInputWrapper>
          <Styled.RowInputWrapper>
            <Styled.InputLabelText>Response Code</Styled.InputLabelText>
            <Input
              value={responseCode}
              type={'number'}
              onChange={(e) => setResponseCode(Number(e.currentTarget.value))}
            />
          </Styled.RowInputWrapper>
        </Styled.RowWrapper>
        <Styled.SmallInputWrapper>
          <Styled.InputLabelText>Request</Styled.InputLabelText>
          <Styled.JSONInput
            value={request}
            aria-label={'Enter Request JSON'}
            placeholder={'Enter Request JSON'}
            onChange={(e) => setRequest(e.target.value)}
          />
        </Styled.SmallInputWrapper>
        <Styled.SmallInputWrapper>
          <Styled.InputLabelText>Response</Styled.InputLabelText>
          <Styled.JSONInput
            value={response}
            aria-label={'Enter Response JSON'}
            placeholder={'Enter Response JSON'}
            onChange={(e) => setResponse(e.target.value)}
          />
        </Styled.SmallInputWrapper>
      </div>
    </Styled.LargeModal>
  )
}

export default AddOperationModal
