import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'tendo-ui'
import { theme } from './theme'

interface AppThemeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default function AppTheme({ children }: AppThemeProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}
