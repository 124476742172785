import { SessionMockSchema, sessionOperations } from '../sessionUtils'

export const getOrder: SessionMockSchema = {
  operation: sessionOperations.GetOrder,
  title: 'Success',
  name: 'GetOrder',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      orderAlias: {
        tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
        systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
        externalType: 'order:id',
        externalId: '54321',
      },
      patientAlias: {
        tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
        systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
        externalType: 'patient:id',
        externalId: '12345',
      },
      orderType: 'ORDER',
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      order: {
        order: {
          externalAlias: {
            tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
            systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
            externalType: 'order:id',
            externalId: '199658',
          },
          description: 'crutches',
          orderType: 'ORDER',
          orderDate: '2022-08-01T00:00:00Z',
          status: 'CLOSED',
          clinicalProvider: {
            externalAlias: {
              tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
              systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
              externalType: 'clinical_provider:id',
              externalId: '12526653',
            },
            name: 'Ather Mansoor MD',
          },
          orderingProvider: {
            externalAlias: {
              tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
              systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
              externalType: 'provider:id',
              externalId: '189',
            },
          },
          orderDiagnoses: [
            {
              description: 'Fracture of ankle',
              codes: [
                {
                  system: 'ICD10',
                  code: 'S82.90XA',
                  display: 'Unsp fracture of unsp lower leg, init for clos fx',
                },
                {
                  system: 'SNOMED',
                  code: '16114001',
                  display: 'Fracture of ankle',
                },
              ],
            },
          ],
          patient: {
            externalAlias: {
              tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
              systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
              externalType: 'patient:id',
              externalId: '12345',
            },
          },
          location: {
            externalAlias: {
              tenantId: 'b9abb02a-14d2-4268-84c5-8593c7a77c77',
              systemId: '85dd997e-4e0c-4c19-a8d5-b21ea773af5f',
              externalType: 'department:id',
              externalId: '195',
            },
          },
        },
        images: [
          {
            reference: {
              key: '32a468c5-9b9c-4f26-8f28-38d1fa936608',
            },
            contentType: 'image/png',
            pageNumber: '1',
          },
        ],
      },
    },
    null,
    2
  ),
}
