import { Title } from 'tendo-ui'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import TendoLogo from 'assets/images/TendoLogoNew.svg'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.rose};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 16px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;

  // React SVG wraps the svg in a div that div doesn't center
  // with the avatar so we're setting it here
  .tendo-log-wrapper {
    div {
      align-items: center;
    }
  }
  .titleWrapper {
    justify-content: center;
    margin: auto;
  }
`

const LogoWrapper = styled.div`
  flex: 1;
`

interface ToolbarProps {
  name?: string
}

export default function Toolbar({ name }: ToolbarProps): JSX.Element {
  return (
    <Wrapper>
      <LogoWrapper>
        <ReactSVG
          src={TendoLogo}
          aria-label="Tendo logo"
          className="tendo-log-wrapper"
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'height: 19px; width: 86px')
          }}
        />
      </LogoWrapper>
      <Title className="titleWrapper" size={45} level={1} color={'white'}>
        {name}
      </Title>
      <LogoWrapper />
    </Wrapper>
  )
}
