import axios from 'axios'
import styled from 'styled-components'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { List, Spin } from 'antd'
import { Title, Card } from 'tendo-ui'
import {
  FHIRQuestionnaireResponse,
  FHIRQuestion,
  FHIRQuestionAnswer,
} from 'types/fhirTypes'
import Divider from './Divider'
import FullWidthSpacer from './FullWidthSpacer'

type MappedQuestion = {
  level: number
} & FHIRQuestion

const url = '/fhir/v1/UI/QuestionnaireResponse'

const TitleWrapper = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
`

const getChildren = (item: FHIRQuestion, level: number): MappedQuestion[] => {
  const children = item.item || []

  const newItem = {
    level,
    ...item,
  }

  //eslint-disable-next-line no-use-before-define
  return [newItem, ...extractChildren(children, level + 1)]
}

const extractChildren = (items: FHIRQuestion[], level: number) => {
  return items.flatMap((item) => getChildren(item, level))
}

const Header = styled.div`
  font-weight: 2500;
  font-family: ${({ theme }) => theme.fonts.medium};
  align-items: center;
  text-align: center;
  justify-content: center;
`

const ItemAnswerWrapper = styled.div``

const QuestionnaireResponseItem = (item: MappedQuestion) => {
  const getValueString = (value: FHIRQuestionAnswer) => {
    if (value.valueBoolean !== undefined) {
      return `${value.valueBoolean}`
    }

    if (value.valueDecimal !== undefined) {
      return `${value.valueDecimal}`
    }

    if (value.valueInteger !== undefined) {
      return `${value.valueInteger}`
    }

    if (value.valueDate) {
      return `${value.valueDate}`
    }

    if (value.valueDateTime) {
      return `${value.valueDateTime}`
    }

    if (value.valueTime) {
      return `${value.valueTime}`
    }

    if (value.valueString) {
      return `${value.valueString}`
    }

    if (value.valueCoding) {
      return `Coding code: ${value.valueCoding.code} - Display: ${
        value.valueCoding.display || 'none'
      }`
    }

    if (value.valueQuantity) {
      const quantityString = `Quantity: ${value.valueQuantity.value}`
      const unitString = value.valueQuantity.code
        ? `of ${value.valueQuantity.code}`
        : ``
      return quantityString + unitString
    }

    if (value.valueString) {
      return `${value.valueString}`
    }

    return ''
  }

  const getAnswerText = (answers: FHIRQuestionAnswer[]) => {
    let answerText = ''

    for (const answer of answers) {
      answerText = answerText + getValueString(answer) + '\n'
    }

    return answerText
  }

  return (
    <List.Item
      key={item.linkId}
      style={{ paddingLeft: item.level * 25, width: '750px' }}
    >
      <List.Item.Meta
        title={item.text ? item.text + '?' : ''}
        description={'Link ID: ' + item.linkId}
      />
      <ItemAnswerWrapper>
        {item.answer ? getAnswerText(item.answer) : ''}
      </ItemAnswerWrapper>
    </List.Item>
  )
}

const QuestionnaireResponseCard = (response: FHIRQuestionnaireResponse) => {
  const baseItems = response.item || []
  const mappedItems = extractChildren(baseItems, 0)
  return (
    <Card>
      <Header>
        <Title level={2}>Questionnaire Response ID: {response.id || ''}</Title>
      </Header>
      <Divider />
      <List>{mappedItems.map((item) => QuestionnaireResponseItem(item))}</List>
    </Card>
  )
}

const loadResponses = (
  isLoading: boolean,
  responses: FHIRQuestionnaireResponse[]
) => {
  if (isLoading) {
    return (
      <TitleWrapper>
        <Spin />
      </TitleWrapper>
    )
  }
  if (responses.length > 0) {
    const sorted = responses.sort((a, b) => {
      if (a.meta && b.meta) {
        const timeA = moment(a.meta.lastUpdated)
        const timeB = moment(b.meta.lastUpdated)

        return timeA.diff(timeB) * -1
      }
      return 0
    })
    return sorted.map((resp) => QuestionnaireResponseCard(resp))
  } else {
    return (
      <TitleWrapper>
        <Title level={1} size={35}>
          No Questionnaire Reponses
        </Title>
      </TitleWrapper>
    )
  }
}

const Content = styled(FullWidthSpacer).attrs({
  direction: 'vertical',
  size: 30,
})`
  padding: 30px 16px;
  align-items: center;
  display: flex;
  width: 100%;
`

const QuestionnaireResponses = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [responses, setResponses] = useState<FHIRQuestionnaireResponse[]>([])

  useEffect(() => {
    axios.get<FHIRQuestionnaireResponse[]>(url).then((response) => {
      if (response?.data?.length > 0) {
        setResponses(response.data)
      }
      setIsLoading(false)
    })
  }, [])

  return <Content>{loadResponses(isLoading, responses)}</Content>
}

export default QuestionnaireResponses
