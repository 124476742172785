import { SessionMockSchema, sessionOperations } from '../sessionUtils'

export const submitForm: SessionMockSchema = {
  operation: sessionOperations.SubmitForm,
  title: 'Success',
  name: 'SubmitForm',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      patientAlias: {
        tenantId: '1bcc8f8f-bdff-436f-a2ca-77d9f0c0861c',
        systemId: 'd226a5fc-240e-4b9b-9ad7-1f6eaaa4d5b0',
        externalType: 'patient:id',
        externalId: '1',
      },
      departmentAlias: {
        tenantId: '1bcc8f8f-bdff-436f-a2ca-77d9f0c0861c',
        systemId: 'd226a5fc-240e-4b9b-9ad7-1f6eaaa4d5b0',
        externalType: 'department:id',
        externalId: '1',
      },
      content: {
        bucket: 'bucket',
        key: 'key',
      },
      formType: 'FORM_TYPE_CONSENT',
      fileType: 'FILE_TYPE_PDF',
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      formAlias: {
        systemId: 'd226a5fc-240e-4b9b-9ad7-1f6eaaa4d5b0',
        externalType: 'admin_document:id',
        externalId: '0',
      },
    },
    null,
    2
  ),
}
