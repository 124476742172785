import { Middleware } from '@reduxjs/toolkit'
import { setAuthToken } from 'store/credentialsReducer'
import { store } from 'store/store'

export const authTokenExpirationMiddleWare: Middleware =
  () => (next) => (action) => {
    // Reset auth token of status 401: unauthorized
    const status = action?.meta?.baseQueryMeta?.response?.status
    if (status === 401) {
      store.dispatch(setAuthToken({ authToken: '' }))
    }
    return next(action)
  }
