import { DefaultRenderText } from './ResourceTable'
import { useState } from 'react'
import {
  KyruusV9Provider,
  KyruusV9ProviderDegree,
  KyruusV9ProviderSearchResponse,
  KyruusV9ProviderSpecialty,
  ParsedProvider,
  ResourceType,
} from 'types/types'
import { Tag } from 'tendo-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import KyruusResourceTable from './KyruusResourceTable'
import { SetProvidersWithDispatch } from 'store/resourceReducer'
import { GetKyruusProviders } from 'service/ServiceApi'

const ParseProvider = (provider: KyruusV9Provider) => {
  const parsed: ParsedProvider = {
    ID: provider.id,
    Degrees: provider.degrees,
    ExternalID: provider.external_id,
    Gender: provider.gender,
    ImageURL: provider.image_url,
    Specialties: provider.specialties,
    Name: '',
  }

  if (provider.name.full) {
    parsed.Name = provider.name.full
    return parsed
  }

  if (provider.name.first) {
    parsed.Name = provider.name.first
  }

  if (provider.name.last) {
    parsed.Name += provider.name.last
  }

  return parsed
}

const renderSpecialty = (specialties: KyruusV9ProviderSpecialty[]) => {
  if (specialties && specialties.length > 0) {
    const parsedSpecialties = []

    for (const spec of specialties) {
      if (spec) {
        parsedSpecialties.push(spec)
      }
    }

    return (
      <>
        {parsedSpecialties.map((specialty) => (
          <Tag key={specialty.name} color="orange" shape="pill" mb={0}>
            {specialty.name || ''}
          </Tag>
        ))}
      </>
    )
  }
}

const renderDegree = (degrees: KyruusV9ProviderDegree[]) => {
  if (degrees && degrees.length > 0) {
    const parsedDegrees = []

    for (const deg of degrees) {
      if (deg) {
        parsedDegrees.push(deg)
      }
    }

    return (
      <>
        {parsedDegrees.map((degree) => (
          <Tag key={degree.name} color="orange" shape="pill" mb={0}>
            {degree.name}
          </Tag>
        ))}
      </>
    )
  }
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'ID',
    key: 'ID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as ParsedProvider).ID - (b as ParsedProvider).ID,
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    render: DefaultRenderText,
  },
  {
    title: 'External ID',
    dataIndex: 'ExternalID',
    key: 'ExternalID',
    render: DefaultRenderText,
  },
  {
    title: 'Gender',
    dataIndex: 'Gender',
    key: 'Gender',
    render: DefaultRenderText,
  },
  {
    title: 'Specialties',
    dataIndex: 'Specialties',
    key: 'Specialties',
    render: renderSpecialty,
  },
  {
    title: 'Degrees',
    dataIndex: 'Degrees',
    key: 'Degrees',
    render: renderDegree,
  },
]

const ProviderIDSelector = (provider: ParsedProvider) => provider.ID.toString()

const Providers = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const providers = useAppSelector((state) => state.resources.providers)

  const onComplete = (response: KyruusV9ProviderSearchResponse) => {
    SetProvidersWithDispatch(dispatch)(response)
    setIsLoading(false)
  }

  const refreshProviders = () => {
    setIsLoading(true)
    GetKyruusProviders(onComplete, () => setIsLoading(false))
  }

  return (
    <KyruusResourceTable
      title={'Providers'}
      dataSource={providers.map((provider) => ParseProvider(provider))}
      columns={columns}
      loading={isLoading}
      resourceType={'providers'}
      idSelector={ProviderIDSelector}
      refreshResource={refreshProviders}
    />
  )
}

export default Providers
