import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const confirmAppointmentSuccess: SessionMockSchema = {
  operation: sessionOperations.ConfirmAppointment,
  title: 'Success',
  name: 'ConfirmAppointment',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: '9835478c-f115-46b3-997d-fc2e4223dbbc',
        systemId: '9d63233b-807d-446d-a3b3-115e69a09b36',
        externalType: 'appointment:id',
        externalId: '456',
      },
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: '9835478c-f115-46b3-997d-fc2e4223dbbc',
        systemId: '9d63233b-807d-446d-a3b3-115e69a09b36',
        externalType: 'appointment:id',
        externalId: '456',
      },
    },
    null,
    2
  ),
}
