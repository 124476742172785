import { AthenaV1Appointment, ResourceType } from 'types/types'
import { DefaultRenderText } from './ResourceTable'
import { useState } from 'react'
import AthenaResourceTable from './AthenaResourceTable'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { SetAppointmentsWithDispatch } from 'store/resourceReducer'
import { GetAthenaAppointments } from 'service/ServiceApi'
import { Text } from 'tendo-ui'

const columns = [
  {
    title: 'ID',
    dataIndex: 'AppointmentID',
    key: 'AppointmentID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Appointment).AppointmentID.localeCompare(
        (b as AthenaV1Appointment).AppointmentID
      ),
  },
  {
    title: 'Status',
    dataIndex: 'AppointmentStatus',
    key: 'AppointmentStatus',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Appointment).AppointmentStatus.localeCompare(
        (b as AthenaV1Appointment).AppointmentStatus
      ),
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
    render: DefaultRenderText,
  },
  {
    title: 'Start Time',
    dataIndex: 'StartTime',
    key: 'StartTime',
    render: DefaultRenderText,
  },
  {
    title: 'Duration',
    dataIndex: 'Duration',
    key: 'Duration',
    render: DefaultRenderText,
  },
  {
    title: 'Patient ID',
    dataIndex: 'PatientID',
    key: 'PatientID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Appointment).PatientID.localeCompare(
        (b as AthenaV1Appointment).PatientID
      ),
  },
  {
    title: 'Encounter ID',
    dataIndex: 'EncounterID',
    key: 'EncounterID',
    render: DefaultRenderText,
  },
  {
    title: 'Provider ID',
    dataIndex: 'ProviderID',
    key: 'ProviderID',
    render: DefaultRenderText,
  },
  {
    title: 'Encounter State',
    dataIndex: 'EncounterState',
    key: 'EncounterState',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Appointment).EncounterState.localeCompare(
        (b as AthenaV1Appointment).EncounterState
      ),
  },
  {
    title: 'Confirmation',
    dataIndex: 'AppointmentConfirmationName',
    key: 'AppointmentConfirmationName',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Appointment).AppointmentConfirmationName.localeCompare(
        (b as AthenaV1Appointment).AppointmentConfirmationName
      ),
  },
]

const AppointmentIDSelector = (appt: AthenaV1Appointment) => appt.AppointmentID

const footerText = `Status Key: "x"=canceled, "f"=booked, "3" and "4"=checked out (aka
  complete)`

const Appointments = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const appointments = useAppSelector((state) => state.resources.appointments)

  const onComplete = (appointments: AthenaV1Appointment[]) => {
    SetAppointmentsWithDispatch(dispatch)(appointments)
    setIsLoading(false)
  }

  const refreshAppointments = () => {
    setIsLoading(true)
    GetAthenaAppointments(onComplete, () => setIsLoading(false))
  }

  const footer = () => <Text variant="body">{footerText}</Text>

  return (
    <AthenaResourceTable
      title={'Appointments'}
      dataSource={appointments}
      columns={columns}
      loading={isLoading}
      resourceType={'appointments'}
      idSelector={AppointmentIDSelector}
      footer={footer}
      refreshResource={refreshAppointments}
    />
  )
}

export default Appointments
