import * as Styled from './Styles'
import Toolbar from 'components/Toolbar'
import { Title, Button } from 'tendo-ui'
import { ResourceDisplay } from 'ResourceApp'
import { useRoutes, useNavigate } from 'react-router-dom'
import { SessionApp } from 'SessionApp'

function ScarfVersionSelector(): JSX.Element {
  const navigate = useNavigate()

  const onSelectV1 = () => {
    navigate('/v1', { replace: false })
  }

  const onSelectV2 = () => {
    navigate('/v2', { replace: false })
  }
  return (
    <Styled.ScarfVersionContent>
      <Title size={25} level={1}>
        Select Scarf Version
      </Title>
      <Styled.MiddleContent>
        <Button key="1" onClick={onSelectV1}>
          V1 Scarf
        </Button>
        <Styled.ButtonWrapper>
          <Button key="2" onClick={onSelectV2}>
            V2 Scarf
          </Button>
        </Styled.ButtonWrapper>
      </Styled.MiddleContent>
    </Styled.ScarfVersionContent>
  )
}

export default function AuthenticatedApp(): JSX.Element {
  const Routes = useRoutes([
    {
      path: '/',
      element: <ScarfVersionSelector />,
    },
    {
      path: '/v1',
      element: <ResourceDisplay />,
    },
    {
      path: '/v2',
      element: <SessionApp />,
    },
  ])

  return (
    <>
      <Toolbar name={`SCARF`} />
      {Routes}
    </>
  )
}
