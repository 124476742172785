import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const rescheduleAppointmentSuccess: SessionMockSchema = {
  operation: sessionOperations.RescheduleAppointment,
  title: 'Success',
  name: 'RescheduleAppointment',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      oldAppointmentAlias: {
        tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
        systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
        externalType: 'appointment:id',
        externalId: '456',
      },
      newSlotAlias: {
        tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
        systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
        externalType: 'appointment:id',
        externalId: '1202758',
      },
      patientAlias: {
        tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
        systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
        externalType: 'patient:id',
        externalId: '1',
      },
      reason: 'I must',
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      appointment: {
        externalAlias: {
          tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
          systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
          externalType: 'appointment:id',
          externalId: '1208991',
        },
        status: 'Fulfilled',
        appointmentType: 'FOLLOW UP 30',
        slot: {
          externalAlias: {
            tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
            systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
            externalType: 'appointment:id',
            externalId: '1208991',
          },
          startTime: '2022-04-12T13:00:00Z',
          endTime: '2022-04-12T13:30:00Z',
          provider: {
            externalAlias: {
              tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
              systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
              externalType: 'provider:id',
              externalId: '111',
            },
          },
          location: {
            externalAlias: {
              tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
              systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
              externalType: 'department:id',
              externalId: '1',
            },
          },
        },
        encounter: {
          externalAlias: {
            tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
            systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
            externalType: 'encounter:id',
            externalId: '42470',
          },
          patient: {
            externalAlias: {
              tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
              systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
              externalType: 'patient:id',
              externalId: '26509',
            },
          },
        },
        patient: {
          externalAlias: {
            tenantId: 'e4df4a4c-ff5c-497a-8a6c-2ca6025dded7',
            systemId: '2bdebaf3-f4e9-4fd7-a074-3dd708dedc48',
            externalType: 'patient:id',
            externalId: '26509',
          },
        },
        appointmentTypeId: '1186',
      },
    },
    null,
    2
  ),
}
