import { colors, lightTheme } from 'tendo-ui'
import 'tendo-ui/dist/fonts/fonts.css'

// only use colorStyles for elements that reference colors that
// need to change when the theme changes (this likely won't be used
// for a while -- until dark/dynamic theme becomes a requirement
export const colorStyles = {
  errorText: colors.rose, // example
}

export type AppTheme = typeof theme

export const theme = {
  ...lightTheme,
  colorStyles,
}
