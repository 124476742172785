import { Divider } from 'antd'
import { Title } from 'tendo-ui'
import * as Styled from '../Styles'

interface ConfirmationModalModalProps {
  visible: boolean
  title: string
  isError?: boolean
  description: string
  onClose(): void
  onTerminate?: () => void
  confirmText?: string
  cancelText?: string
}

function ConfirmationModal(props: ConfirmationModalModalProps): JSX.Element {
  const footer = (
    <>
      <Styled.FooterButton
        variant={props.isError ? 'danger' : 'stroke'}
        onClick={props.onClose}
      >
        {props.cancelText || 'Cancel'}
      </Styled.FooterButton>
      {!!props.onTerminate && (
        <Styled.FooterButton onClick={props.onTerminate}>
          {props.confirmText || 'Yes'}
        </Styled.FooterButton>
      )}
    </>
  )

  return (
    <Styled.SmallModal
      open={props.visible}
      onCancel={props.onClose}
      closable={true}
      footer={footer}
      style={{ borderRadius: 25 }}
    >
      <Styled.CenteredHeader>
        <Title
          level={1}
          variant={'headline'}
          color={props.isError ? 'red' : undefined}
        >
          {props.title}
        </Title>
      </Styled.CenteredHeader>
      <Divider />
      <Styled.SpacedText variant="body">{props.description}</Styled.SpacedText>
    </Styled.SmallModal>
  )
}

export default ConfirmationModal
