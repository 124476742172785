import { notification, Row, Col, Layout, Menu } from 'antd'
import type { MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import { Title } from 'tendo-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  AddOperationToSession,
  AddSessionOperationRequest,
} from 'service/SessionService'
import { toggleModalVisibility } from 'store/modalReducer'
import { OperationMethod } from 'types/sessions'
import * as Styled from '../../Styles'
import { AddItemToSessionStateWithDispatch } from 'store/sessionReducer'
import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'
import OperationMockPreview from './OperationMockPreview'
import { sessionOperationMocks } from 'sessionTemplates/sessionMapping'

export interface AddOperationModalProps {
  destroyOnClose?: boolean
}

const { Sider } = Layout

const SessionOperationTemplatesModal = (props: AddOperationModalProps) => {
  const [operationName, setOperationName] = useState<string>('')
  const [operationMethod, setOperationMethod] =
    useState<OperationMethod>('POST')
  const [responseCode, setResponseCode] = useState<number>(200)
  const [request, setRequest] = useState<string>('')
  const [response, setResponse] = useState<string>('')
  const [tenantID, setTenantID] = useState<string>('')
  const [systemID, setSystemID] = useState<string>('')
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const sessionID = useAppSelector((state) => state.sessions.sessionID)
  const visible = useAppSelector(
    (state) => state.modalVisibility.sessionOperationTemplatesVisible
  )

  const setSelectedMock = (selectedMock?: SessionMockSchema) => {
    setOperationName(selectedMock?.name || '')
    setOperationMethod(selectedMock?.responseMethod || 'POST')
    setResponseCode(selectedMock?.responseCode || 200)
    setRequest(selectedMock?.request || '')
    setResponse(selectedMock?.response || '')
    setTenantID('')
    setSystemID('')
  }

  useEffect(() => {
    setSelectedMock()
  }, [visible])

  const onClose = () => {
    dispatch(toggleModalVisibility('sessionOperationTemplatesVisible'))
  }

  const onFailAddOperation = () => {
    notification['error']({
      message: 'Error adding Operation to Session',
      description: `Unable to add ${operationName} to sessionID: ${sessionID}`,
    })
  }

  const onSuccessAddOperation = () => {
    notification['success']({
      message: 'Success',
      description: `Successfully added ${operationName} to sessionID: ${sessionID}`,
    })
  }

  const onAdd = () => {
    const addRequest: AddSessionOperationRequest = {
      sessionID,
      operationName,
      operation: {
        request,
        method: operationMethod,
        // These two are default empty for now
        requestQuery: '',
        requestHeaders: new Map<string, string[]>(),
        // Response details
        response,
        responseCode,
        // DEFAULTED
        responseContentType: 'application/json',
        // Default empty for now
        responseHeaders: new Map<string, string[]>(),
      },
    }

    AddOperationToSession(
      addRequest,
      (i: string) => {
        setIsButtonLoading(true)
        AddItemToSessionStateWithDispatch(dispatch)(i, addRequest)
        onSuccessAddOperation()
        setIsButtonLoading(false)
        onClose()
      },
      onFailAddOperation
    )
  }

  const sessionOperationList = Object.values(sessionOperations)

  const formattedOperationList: MenuProps['items'] = sessionOperationList.map(
    (key) => {
      const emptyItem = { key, label: `${key}` }
      const hasChildren = sessionOperationMocks.has(key as sessionOperations)
      if (!hasChildren) {
        return emptyItem
      }
      const children = sessionOperationMocks.get(key as sessionOperations)
      if (!children) {
        return emptyItem
      }
      const formattedChildren: MenuProps['items'] = children.map(
        (child, index) => {
          return {
            label: child.title,
            key: `${child.operation}-${index}`,
          }
        }
      )
      return {
        key,
        label: key,
        children: formattedChildren,
      }
    }
  )

  const setMockFromKey = (key: string) => {
    const [operation, index] = key.split('-')
    if (!operation) {
      return
    }
    const selectedOperation = sessionOperationMocks.get(
      operation as sessionOperations
    )
    if (!selectedOperation) {
      return
    }
    const selectedMock = selectedOperation[Number(index)]
    if (selectedMock) {
      setSelectedMock(selectedMock)
    }
  }

  return (
    <Styled.ExtraLargeModal
      open={visible}
      closable={true}
      onCancel={onClose}
      okText={'Add'}
      cancelButtonProps={{ shape: 'round' }}
      okButtonProps={{
        disabled: operationName === '' || tenantID === '' || systemID === '',
        shape: 'round',
        loading: isButtonLoading,
      }}
      onOk={onAdd}
      {...props}
    >
      <Styled.Content>
        <Title level={1} variant={'headline'}>
          Session Operation Templates
        </Title>
      </Styled.Content>
      <Row>
        <Col span={6}>
          <Title level={1} variant={'headline'}>
            Templates
          </Title>
          <Sider width={200} className="site-layout-background">
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', borderRight: 0 }}
              items={formattedOperationList}
              onClick={({ key }) => setMockFromKey(key)}
            />
          </Sider>
        </Col>
        <Col span={18}>
          <Title level={1} variant={'headline'}>
            Preview
          </Title>
          <OperationMockPreview
            setOperationName={setOperationName}
            setOperationMethod={setOperationMethod}
            setResponseCode={setResponseCode}
            setRequest={setRequest}
            setResponse={setResponse}
            setSystemID={setSystemID}
            setTenantID={setTenantID}
            operationName={operationName}
            responseMethod={operationMethod}
            responseCode={responseCode}
            request={request}
            response={response}
            tenantID={tenantID}
            systemID={systemID}
          />
        </Col>
      </Row>
    </Styled.ExtraLargeModal>
  )
}

export default SessionOperationTemplatesModal
