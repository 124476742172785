import { Text, TextArea, Button, Drawer, Title } from 'tendo-ui'
import styled from 'styled-components'
import FullWidthSpacer from 'components/FullWidthSpacer'
import { Modal } from 'antd'

export const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.space100};
  padding-top: 68px;
`

export const Content = styled(FullWidthSpacer).attrs({
  direction: 'vertical',
  size: 30,
})`
  padding: 30px 16px;
  align-items: center;
  display: flex;
  width: 100%;
`

export const Label = styled(Text).attrs({ variant: 'label' })`
  padding: 15px 25px 15px 0px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
`

export const ButtonWrapper = styled.div`
  padding-left: 20px;
`

export const PaddedButton = styled(Button)`
  padding-left: 20px;
`

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const MiddleContent = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const ScarfVersionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  justify-content: center;
  align-items: center;
`

export const PaddingWrapper = styled.div`
  padding: 25px 0px 25px;
`

export const LargePaddingWrapper = styled.div`
  padding: 40px 0px 40px;
`

export const ExtraLargeModal = styled(Modal).attrs({ width: '1250px' })`
  && {
    margin: 100;
    top: 50;
    max-width: unset;
    z-index: 4;
  }
`

export const LargeModal = styled(Modal).attrs({ width: '950px' })`
  && {
    margin: 100;
    top: 50;
    max-width: unset;
    z-index: 3;
  }
`

export const SmallInputWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
`

export const SmallModal = styled(Modal).attrs({ width: '600px' })`
  && {
    margin: 100;
    top: 50;
    max-width: unset;
    z-index: 3;
  }
`

export const FooterButton = styled(Button)`
  margin-right: 10px;
`

export const CenteredHeader = styled.div`
  font-weight: 2500;
  align-items: center;
  text-align: center;
`

export const SpacedText = styled(Text)`
  && {
    padding: 5px 0 35px;
  }
`

export const LargeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 30px 0px;
`

export const InputLabelText = styled(Text).attrs({ variant: 'label' })`
  && {
    padding: 5px 0 3px;
  }
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const RowDisplayWrapper = styled.div`
  padding: 15px 10px 0px;
  display: flex;
  flex-direction: column;
`

export const RowInputWrapper = styled.div`
  padding: 15px 0px 0px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
`

export const JSONInput = styled(TextArea).attrs({ rows: 8 })``

export const DrawerContainer = styled(Drawer)`
  // drawer close icon
  & .anticon {
    color: #323246;
    left: 20px;
    top: 20px;
    position: absolute;
  }

  .ant-drawer-footer {
    background: #666665;
  }
`

export const DrawerContent = styled.div`
  padding: 0;
  width: 100%;
`

export const DrawerWrapper = styled.div`
  padding-bottom: 60px;
`

export const DrawerTitle = styled(Title).attrs({
  level: 1,
  component: 'h1',
  variant: 'headline',
})`
  && {
    align-items: center;
  }
`

export const DrawerText = styled(Text).attrs({ color: '#666665' })`
  && {
    text-align: left;
    margin: 16px 0 8px;
  }
`

export const DrawerHeader = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px;
`

export const DrawerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 32px;
  position: absolute;
  margin-left: -28px;
  bottom: 0;
  width: 650px;
  background: #f7f7fa;
`
