import { useState } from 'react'
import { Menu } from 'antd'
import * as Styled from './Styles'
import { Text } from 'tendo-ui'
import SessionManager from 'components/sessions/SessionManager'
import AddOperationModal from 'components/sessions/AddOperationModal'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import SessionOperationTemplatesModal from 'components/sessions/SessionOperationTemplatesModal'

export const SessionApp = (): JSX.Element => {
  const [selectedKey, setSelectedKey] = useState('session')

  const getOnClick = (key: string) => {
    return () => {
      setSelectedKey(key)
    }
  }

  const getContent = () => {
    switch (selectedKey) {
      case 'session':
        return <SessionManager />
      default:
        return <Text variant="body">Unknown Option</Text>
    }
  }

  const items: ItemType[] = [
    {
      label: 'Session',
      key: 'session',
      onClick: getOnClick('session'),
    },
  ]

  return (
    <Styled.Wrapper>
      <Menu selectedKeys={[selectedKey]} mode="horizontal" items={items} />
      <Styled.Content>{getContent()}</Styled.Content>
      <AddOperationModal key={'addSession'} destroyOnClose={true} />
      <SessionOperationTemplatesModal
        key={'openSessionOperationTemplateModal'}
        destroyOnClose={true}
      />
    </Styled.Wrapper>
  )
}
