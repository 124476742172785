import { AthenaV1Patient, ResourceType } from 'types/types'
import { DefaultRenderText } from './ResourceTable'
import { useState } from 'react'
import AthenaResourceTable from './AthenaResourceTable'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { GetAthenaPatients } from 'service/ServiceApi'
import { SetPatientsWithDispatch } from 'store/resourceReducer'

const columns = [
  {
    title: 'ID',
    dataIndex: 'PatientID',
    key: 'PatientID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Patient).PatientID.localeCompare(
        (b as AthenaV1Patient).PatientID
      ),
  },
  {
    title: 'First Name',
    dataIndex: 'FirstName',
    key: 'FirstName',
    render: DefaultRenderText,
  },
  {
    title: 'Last Name',
    dataIndex: 'LastName',
    key: 'LastName',
    render: DefaultRenderText,
  },
  {
    title: 'Sex',
    dataIndex: 'Sex',
    key: 'Sex',
    render: DefaultRenderText,
  },
  {
    title: 'Date of Birth',
    dataIndex: 'dob',
    key: 'dob',
    render: DefaultRenderText,
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'Email',
    render: DefaultRenderText,
  },
  {
    title: 'City',
    dataIndex: 'City',
    key: 'City',
    render: DefaultRenderText,
  },
  {
    title: 'State',
    dataIndex: 'State',
    key: 'State',
    render: DefaultRenderText,
  },
  {
    title: 'ZIP',
    dataIndex: 'Zip',
    key: 'Zip',
    render: DefaultRenderText,
  },
]

const PatientIDSelector = (patient: AthenaV1Patient) => patient.PatientID

const Patients = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const patients = useAppSelector((state) => state.resources.patients)

  const onComplete = (patients: AthenaV1Patient[]) => {
    SetPatientsWithDispatch(dispatch)(patients)
    setIsLoading(false)
  }

  const refreshPatients = () => {
    setIsLoading(true)
    GetAthenaPatients(onComplete, () => setIsLoading(false))
  }

  return (
    <AthenaResourceTable
      title={'Patients'}
      dataSource={patients}
      columns={columns}
      loading={isLoading}
      resourceType={'patients'}
      idSelector={PatientIDSelector}
      refreshResource={refreshPatients}
    />
  )
}

export default Patients
