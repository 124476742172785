import { AthenaV1Slot, ResourceType } from 'types/types'
import { DefaultRenderText } from './ResourceTable'
import { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import AthenaResourceTable from './AthenaResourceTable'
import { Input, Button, Text } from 'tendo-ui'
import styled from 'styled-components'
import * as Styled from '../Styles'
import {
  AthenaDeleteIDs,
  SlotResponse,
  useLazyGetAthenaSlotsQuery,
} from '../service/ServiceApi'
import { toggleModalVisibility } from 'store/modalReducer'
import moment from 'moment'

const columns = [
  {
    title: 'ID',
    dataIndex: 'AppointmentID',
    key: 'AppointmentID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Slot).AppointmentID - (b as AthenaV1Slot).AppointmentID,
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) => {
      const dateA = moment(
        `${(a as AthenaV1Slot).Date} ${(a as AthenaV1Slot).StartTime}`,
        'MM-DD-YYYY hh:mm'
      )
      const dateB = moment(
        `${(b as AthenaV1Slot).Date} ${(b as AthenaV1Slot).StartTime}`,
        'MM-DD-YYYY hh:mm'
      )
      return dateA.isBefore(dateB) ? -1 : 1
    },
  },
  {
    title: 'Start Time',
    dataIndex: 'StartTime',
    key: 'StartTime',
    render: DefaultRenderText,
  },
  {
    title: 'Duration',
    dataIndex: 'Duration',
    key: 'Duration',
    render: DefaultRenderText,
  },
  {
    title: 'Provider ID',
    dataIndex: 'ProviderID',
    key: 'ProviderID',
    render: DefaultRenderText,
  },
  {
    title: 'Department ID',
    dataIndex: 'DepartmentID',
    key: 'DepartmentID',
    render: DefaultRenderText,
  },
]

const TextWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`

// Helper sleep function
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

const SlotIDSelector = (slot: AthenaV1Slot) => slot.AppointmentID.toString()

const Slots = () => {
  const [providerIdFilter, setProviderIdFilter] = useState<number>()
  const [departmentIdFilter, setDepartmentIdFilter] = useState<number>()
  const [filteredSlots, setFilteredSlots] = useState<AthenaV1Slot[]>([])
  const cachedSlots = useAppSelector(
    (state) => state.getAthenaSlots.queries?.['getAthenaSlots("")']
  )
  const slotStatus = cachedSlots?.status as string
  const slots = (cachedSlots?.data as SlotResponse)?.appointments || []

  const dispatch = useAppDispatch()

  const [refetchSlots, { data: slotData, isLoading }] =
    useLazyGetAthenaSlotsQuery()

  const deleteAllSlots = () => {
    const now = moment()
    const invalidSlotIDs = slots
      .filter((slot) => moment(slot.Date, 'MM-DD-YYYY').isBefore(now))
      .map((slot) => slot.AppointmentID.toString())

    AthenaDeleteIDs('appointments', invalidSlotIDs, () => {
      // Wait for the deletion
      delay(2500)
      refetchSlots('')
    })
  }

  useEffect(() => {
    if (slotStatus === 'fulfilled') {
      setFilteredSlots(slots || [])
    } else {
      refetchSlots('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotStatus])

  const filterSlots = useCallback(() => {
    let filteredSlots = slotData?.appointments || []

    // Both are set
    if (providerIdFilter && departmentIdFilter) {
      const filtered = filteredSlots.filter((s) => {
        return (
          s.ProviderID === providerIdFilter &&
          s.DepartmentID === departmentIdFilter
        )
      })
      filteredSlots = filtered
    } else if (providerIdFilter) {
      // Just filter on providerid
      const filtered = filteredSlots.filter((s) => {
        return s.ProviderID === providerIdFilter
      })
      filteredSlots = filtered
    } else if (departmentIdFilter) {
      // Just filter on departmentid
      const filtered = filteredSlots.filter((s) => {
        return s.DepartmentID === departmentIdFilter
      })
      filteredSlots = filtered
    }
    setFilteredSlots(filteredSlots)
  }, [slotData, departmentIdFilter, providerIdFilter, setFilteredSlots])

  const toggleCreateSlots = useCallback(
    () => dispatch(toggleModalVisibility('createSlotsVisible')),
    [dispatch]
  )

  const header = useCallback(
    () => (
      <>
        <InputWrapper>
          <TextWrapper>
            <Text variant="body">Provider ID:</Text>
            <Input
              value={providerIdFilter || ''}
              onChange={(e) =>
                setProviderIdFilter(Number(e.currentTarget.value))
              }
            />
          </TextWrapper>
          <TextWrapper>
            <Text variant="body">Department ID:</Text>
            <Input
              value={departmentIdFilter || ''}
              onChange={(e) =>
                setDepartmentIdFilter(Number(e.currentTarget.value))
              }
            />
          </TextWrapper>
          <Styled.ButtonWrapper>
            <Button onClick={filterSlots}>Filter</Button>
          </Styled.ButtonWrapper>
          <Styled.ButtonWrapper>
            <Button onClick={deleteAllSlots}>Delete All Slots</Button>
          </Styled.ButtonWrapper>
        </InputWrapper>
      </>
    ),
    [
      providerIdFilter,
      setProviderIdFilter,
      departmentIdFilter,
      setDepartmentIdFilter,
      filterSlots,
    ]
  )

  const memoFooter = useCallback(
    () => (
      <Styled.ButtonWrapper>
        <Button onClick={toggleCreateSlots}>Create Slots</Button>
      </Styled.ButtonWrapper>
    ),
    [toggleCreateSlots]
  )

  return (
    <AthenaResourceTable
      title={'Slots'}
      dataSource={filteredSlots}
      columns={columns}
      loading={isLoading}
      footerButtons={memoFooter}
      resourceType={'appointments'}
      idSelector={SlotIDSelector}
      refreshResource={() => refetchSlots('')}
      header={header}
    />
  )
}

export default Slots
