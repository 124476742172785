import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const listSlotsSuccess: SessionMockSchema = {
  operation: sessionOperations.ListSlots,
  title: 'Success',
  name: 'ListSlots',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      locationAlias: {
        tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
        systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
        externalType: 'department:id',
        externalId: '1',
      },
      providerAlias: {
        tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
        systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
        externalType: 'provider:id',
        externalId: '101010',
      },
      startTime: '',
      endTime: '',
      pageSize: 1000,
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      slots: [
        {
          externalAlias: {
            tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
            systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
            externalType: 'appointment:id',
            externalId: '1202031',
          },
          startTime: '2022-03-09T17:00:00Z',
          endTime: '2022-03-09T17:30:00Z',
          provider: {
            externalAlias: {
              tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
              systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
              externalType: 'provider:id',
              externalId: '1',
            },
          },
          location: {
            externalAlias: {
              tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
              systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
              externalType: 'department:id',
              externalId: '1',
            },
          },
        },
        {
          externalAlias: {
            tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
            systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
            externalType: 'appointment:id',
            externalId: '1202032',
          },
          startTime: '2022-03-09T17:30:00Z',
          endTime: '2022-03-09T18:00:00Z',
          provider: {
            externalAlias: {
              tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
              systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
              externalType: 'provider:id',
              externalId: '1',
            },
          },
          location: {
            externalAlias: {
              tenantId: '25f45b5e-c151-4f51-a660-dda445d32a08',
              systemId: 'a09dbfff-29bf-42cb-828d-70af49e80c01',
              externalType: 'department:id',
              externalId: '1',
            },
          },
        },
      ],
      nextPageToken: 'COgH',
    },
    null,
    2
  ),
}
