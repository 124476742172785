import StyledDrawer from 'components/StyledDrawer'
import { useAppSelector } from 'store/hooks'
import { Select, SelectOption, Input, Button } from 'tendo-ui'
import { Collapse } from 'antd'
import * as Styled from '../../Styles'

const { Panel } = Collapse

const headerDisplay = (headers: Map<string, string[]>) => {
  return (
    <>
      <Collapse>
        {Array.from(headers).map((entry, idx) => (
          <Panel header={entry[0]} key={idx}>
            {entry[1].join(',')}
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

interface OperationDetailDrawerProps {
  operationName: string
  visible?: boolean
  onClose: () => void
  onDelete?: () => void
}

const OperationDetailDrawer = (
  props: OperationDetailDrawerProps
): JSX.Element => {
  const selectedOperation = useAppSelector(
    (state) => state.sessions.selectedSession
  )

  return (
    <StyledDrawer visible={props.visible} onClose={props.onClose}>
      {selectedOperation && (
        <>
          <Styled.DrawerHeader>
            <Styled.DrawerTitle>Operation Match Details</Styled.DrawerTitle>
          </Styled.DrawerHeader>
          <Styled.DrawerWrapper>
            <Styled.SmallInputWrapper>
              <Styled.InputLabelText>Operation</Styled.InputLabelText>
              <Input
                value={props.operationName}
                type={'text'}
                disabled={true}
                width={'100%'}
              />
            </Styled.SmallInputWrapper>
            <Styled.RowWrapper>
              <Styled.SmallInputWrapper>
                <Styled.InputLabelText>
                  Response Content Type
                </Styled.InputLabelText>
                <Input
                  value={selectedOperation.responseContentType}
                  type={'text'}
                  disabled={true}
                  width={'100%'}
                />
              </Styled.SmallInputWrapper>
              <Styled.RowDisplayWrapper>
                <Styled.InputLabelText>Request Method</Styled.InputLabelText>
                <Select
                  width="100%"
                  value={selectedOperation.method}
                  disabled={true}
                >
                  <SelectOption
                    key={selectedOperation.method}
                    value={selectedOperation.method}
                  >
                    {selectedOperation.method}
                  </SelectOption>
                </Select>
              </Styled.RowDisplayWrapper>
              <Styled.RowDisplayWrapper style={{ width: 150 }}>
                <Styled.InputLabelText>Response Code</Styled.InputLabelText>
                <Input
                  value={selectedOperation.responseCode}
                  type={'number'}
                  disabled={true}
                />
              </Styled.RowDisplayWrapper>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.SmallInputWrapper style={{ width: '100%' }}>
                <Styled.InputLabelText>Request Query</Styled.InputLabelText>
                <Input
                  value={selectedOperation.requestQuery}
                  type={'text'}
                  disabled={true}
                  width={'100%'}
                />
              </Styled.SmallInputWrapper>
            </Styled.RowWrapper>
            <Styled.SmallInputWrapper>
              <Styled.InputLabelText>Request Headers</Styled.InputLabelText>
              {headerDisplay(selectedOperation.requestHeaders)}
            </Styled.SmallInputWrapper>
            <Styled.SmallInputWrapper>
              <Styled.InputLabelText>Request</Styled.InputLabelText>
              <Styled.JSONInput
                value={selectedOperation.request}
                disabled={true}
              />
            </Styled.SmallInputWrapper>
            <Styled.SmallInputWrapper>
              <Styled.InputLabelText>Response Headers</Styled.InputLabelText>
              {headerDisplay(selectedOperation.responseHeaders)}
            </Styled.SmallInputWrapper>
            <Styled.SmallInputWrapper>
              <Styled.InputLabelText>Response</Styled.InputLabelText>
              <Styled.JSONInput
                value={selectedOperation.response}
                disabled={true}
              />
            </Styled.SmallInputWrapper>
            <Styled.LargePaddingWrapper />
          </Styled.DrawerWrapper>
          <Styled.DrawerFooter>
            <Button onClick={props.onClose} width="50%">
              Cancel
            </Button>
            <Button onClick={props.onDelete} variant="danger" width="50%">
              Delete
            </Button>
          </Styled.DrawerFooter>
        </>
      )}
    </StyledDrawer>
  )
}

export default OperationDetailDrawer
