import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const listPatientDiagnosticReports: SessionMockSchema = {
  operation: sessionOperations.ListPatientDiagnosticReports,
  title: 'Success',
  name: 'ListPatientDiagnosticReports',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      patientAlias: {
        tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
        systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
        externalType: 'patient:id',
        externalId: '26665',
      },
      locationAliases: [
        {
          tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
          systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
          externalType: 'department:id',
          externalId: '195',
        },
      ],
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      reports: [
        {
          report: {
            externalAlias: {
              tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
              systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
              externalType: 'imageresult:id',
              externalId: '199375',
            },
            type: 'DIAGNOSTIC_REPORT_TYPE_IMAGING',
            patient: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'patient:id',
                externalId: '26665',
              },
            },
            order: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'order:id',
                externalId: '199374',
              },
            },
            provider: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            name: 'MRI, brain, w/o contrast',
            status: 'DATAENTRY',
            observedAt: '0001-01-01T00:00:00Z',
            createdAt: '2022-07-28T19:22:15Z',
            publishedAt: '0001-01-01T00:00:00Z',
          },
          images: [
            {
              reference: {
                key: 'fac5fd24-45cd-405d-b66d-79fe189dbaf7',
              },
              contentType: 'image/png',
              pageNumber: '1',
            },
          ],
        },
        {
          report: {
            externalAlias: {
              tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
              systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
              externalType: 'imageresult:id',
              externalId: '199375',
            },
            type: 'DIAGNOSTIC_REPORT_TYPE_IMAGING',
            patient: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'patient:id',
                externalId: '26665',
              },
            },
            order: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'order:id',
                externalId: '199374',
              },
            },
            provider: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            name: 'MRI, brain, w/o contrast',
            status: 'DATAENTRY',
            observedAt: '0001-01-01T00:00:00Z',
            createdAt: '2022-07-28T19:22:15Z',
            publishedAt: '0001-01-01T00:00:00Z',
          },
          images: [
            {
              reference: {
                key: '6808a5d9-c0e7-4c3c-b195-d8efc0825904',
              },
              contentType: 'image/png',
              pageNumber: '1',
            },
          ],
        },
        {
          report: {
            externalAlias: {
              tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
              systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
              externalType: 'labresult:id',
              externalId: '196190',
            },
            type: 'DIAGNOSTIC_REPORT_TYPE_LAB',
            patient: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'patient:id',
                externalId: '26665',
              },
            },
            encounter: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'encounter:id',
                externalId: '12345',
              },
            },
            order: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'order:id',
                externalId: '197604',
              },
            },
            provider: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'provider:id',
                externalId: '187',
              },
            },
            name: 'streptococcus group B, culture, throat',
            clinicianNote: "You're sick! Sorry :,(",
            status: 'NOTIFY',
            interpretation: 'Imaging interpretation note',
            observedAt: '2022-06-23T08:00:00Z',
            createdAt: '2022-07-01T19:36:26Z',
            publishedAt: '2021-11-23T19:33:09Z',
            labDetails: {
              analytes: [
                {
                  externalAlias: {
                    tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                    systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                    externalType: 'analyte:id',
                    externalId: '20515',
                  },
                  name: 'streptococcus',
                  value: 'Y',
                  note: 'Patient has strep',
                  status: 'final',
                  abnormalFlag: 'positive',
                },
              ],
              laboratoryName: 'In-Office Order',
              isReviewedByProvider: true,
            },
          },
          images: [
            {
              reference: {
                key: 'd3b4a846-2809-44ea-a7e6-46cad331f01a',
              },
              contentType: 'image/jpeg',
              pageNumber: '1',
            },
          ],
        },
        {
          report: {
            externalAlias: {
              tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
              systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
              externalType: 'labresult:id',
              externalId: '196189',
            },
            type: 'DIAGNOSTIC_REPORT_TYPE_LAB',
            patient: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'patient:id',
                externalId: '26665',
              },
            },
            encounter: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'encounter:id',
                externalId: '12345',
              },
            },
            order: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'order:id',
                externalId: '197604',
              },
            },
            provider: {
              externalAlias: {
                tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                externalType: 'provider:id',
                externalId: '187',
              },
            },
            name: 'streptococcus group B, culture, throat',
            clinicianNote: "You're sick! Sorry :,(",
            status: 'NOTIFY',
            interpretation: 'Imaging interpretation note',
            observedAt: '2022-06-23T08:00:00Z',
            createdAt: '2022-07-01T19:36:26Z',
            publishedAt: '2021-11-23T19:33:09Z',
            labDetails: {
              analytes: [
                {
                  externalAlias: {
                    tenantId: '8d5e56c5-26d5-429f-87e4-ec15d3f7e0dd',
                    systemId: 'af6cbaab-b818-4342-87d5-0bf01cb91b91',
                    externalType: 'analyte:id',
                    externalId: '20515',
                  },
                  name: 'streptococcus',
                  value: 'Y',
                  note: 'Patient has strep',
                  status: 'final',
                  abnormalFlag: 'positive',
                },
              ],
              laboratoryName: 'In-Office Order',
              isReviewedByProvider: true,
            },
          },
          images: [
            {
              reference: {
                key: 'b27e5836-2d46-4ea4-85a5-577dae8f6146',
              },
              contentType: 'image/jpeg',
              pageNumber: '1',
            },
          ],
        },
      ],
    },
    null,
    2
  ),
}
