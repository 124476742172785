import { AthenaV1Department, ResourceType } from 'types/types'
import { DefaultRenderText } from './ResourceTable'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import AthenaResourceTable from './AthenaResourceTable'
import { SetDepartmentsWithDispatch } from 'store/resourceReducer'
import { DepartmentResponse, GetAthenaDepartments } from 'service/ServiceApi'

const columns = [
  {
    title: 'ID',
    dataIndex: 'DepartmentId',
    key: 'DepartmentId',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Department).DepartmentId.localeCompare(
        (b as AthenaV1Department).DepartmentId
      ),
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    render: DefaultRenderText,
  },
  {
    title: 'Provider Group Name',
    dataIndex: 'ProviderGroupName',
    key: 'ProviderGroupName',
    render: DefaultRenderText,
  },
  {
    title: 'City',
    dataIndex: 'City',
    key: 'City',
    render: DefaultRenderText,
  },
  {
    title: 'State',
    dataIndex: 'State',
    key: 'State',
    render: DefaultRenderText,
  },
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'Address',
    render: DefaultRenderText,
  },
  {
    title: 'Phone',
    dataIndex: 'Phone',
    key: 'Phone',
    render: DefaultRenderText,
  },
  {
    title: 'Timezone Name',
    dataIndex: 'TimezoneName',
    key: 'TimezoneName',
    render: DefaultRenderText,
  },
]

const DepartmentIDSelector = (department: AthenaV1Department) =>
  department.DepartmentId

const Departments = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const departments = useAppSelector((state) => state.resources.departments)

  const onComplete = (response: DepartmentResponse) => {
    SetDepartmentsWithDispatch(dispatch)(response)
    setIsLoading(false)
  }

  const refreshDepartments = () => {
    setIsLoading(true)
    GetAthenaDepartments(onComplete, () => setIsLoading(false))
  }

  return (
    <AthenaResourceTable
      title={'Departments'}
      dataSource={departments}
      columns={columns}
      loading={isLoading}
      resourceType={'departments'}
      idSelector={DepartmentIDSelector}
      refreshResource={refreshDepartments}
    />
  )
}

export default Departments
