import * as Styled from '../../Styles'
import { Button, Title, Input, Text } from 'tendo-ui'
import { useState } from 'react'
import { Table, notification } from 'antd'
import styled from 'styled-components'
import { DefaultRenderText } from 'components/ResourceTable'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  CreateSession,
  DeleteOperationFromSession,
  DeleteOperationFromSessionRequest,
  GetOperationDetails,
  GetOperationDetailsRequest,
  GetSessionOperations,
  GetSessionOperationsResponse,
} from 'service/SessionService'
import {
  DeleteItemFromSessionStateWithDispatch,
  SetSessionSelectedWithDispatch,
  SetSessionDefinitionWithDispatch,
  ClearSessionSelectedWithDispatch,
} from 'store/sessionReducer'
import { toggleModalVisibility } from 'store/modalReducer'
import ConfirmationModal from 'components/ConfirmationModal'
import { TableRowSelection } from 'antd/lib/table/interface'
import OperationDetailDrawer from './OperationDetailDrawer'
import { SessionOperation } from 'types/sessions'

const columns = [
  {
    title: 'Operation Name',
    dataIndex: 'name',
    key: 'name',
    render: DefaultRenderText,
  },
  {
    title: 'Operation Method',
    dataIndex: 'method',
    key: 'method',
    render: DefaultRenderText,
  },
  {
    title: 'Operation ID',
    dataIndex: 'id',
    key: 'id',
    render: DefaultRenderText,
  },
]

const TextWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`

interface SessionOperationItem {
  name: string
  method: string
  id: string
}

const itemStringToSessionOperationItem = (i: string): SessionOperationItem => {
  const split = i.split('/')
  if (split.length !== 3) {
    return {
      name: i,
      method: '',
      id: '',
    }
  }
  return {
    name: split[0],
    method: split[1],
    id: split[2],
  }
}

const rowKeySelector = (row: SessionOperationItem) =>
  `${row.name}/${row.method}/${row.id}`

function SessionManager(): JSX.Element {
  const [sessionID, setSessionID] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteSessionOperationVisible, setIsDeleteSessionOperationVisible] =
    useState(false)
  const [isDetailDrawerVisible, setIsDetailDrawerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | undefined>()

  const dispatch = useAppDispatch()
  const ops: SessionOperationItem[] = useAppSelector(
    (state) => state.sessions.items
  ).map((i) => itemStringToSessionOperationItem(i))

  const addDisabled: boolean = useAppSelector(
    (state) => state.sessions.sessionID === ''
  )

  const sessionDetailsCache = useAppSelector(
    (state) => state.sessions.sessionCache
  )

  const selectedOperationItem = selectedItem
    ? itemStringToSessionOperationItem(selectedItem)
    : undefined

  const onCompleteGetSessionOperations = (
    response: GetSessionOperationsResponse
  ) => {
    notification['success']({
      message: 'Session Found',
      description: `Successfully found session: ${sessionID}`,
    })
    SetSessionDefinitionWithDispatch(dispatch)({
      sessionID: sessionID,
      items: response.items,
    })
    setIsLoading(false)
  }

  const onFailGetSessionOperations = () => {
    setIsLoading(false)
    // Error toast
    notification['error']({
      message: 'Error Searching for Session',
      description: `Unable to find a session with the given sessionID: ${sessionID}`,
    })
  }

  const onFailCreateSession = (error: any) => {
    setIsLoading(false)
    // Error toast
    notification['error']({
      message: 'Error Creating New Session',
      description: `${error}`,
    })
  }

  const handleSearchSessionID = (sessionID: string) => {
    setIsLoading(true)
    GetSessionOperations(
      sessionID,
      onCompleteGetSessionOperations,
      onFailGetSessionOperations
    )
  }

  const onCompleteSessionCreation = (newSessionID: string) => {
    setSessionID(newSessionID)
    notification['success']({
      message: 'Created New Session',
      description: `Successfully created new session: ${newSessionID}`,
    })
    SetSessionDefinitionWithDispatch(dispatch)({
      sessionID: newSessionID,
      items: [],
    })
    setIsLoading(false)
  }

  const handleCreateSession = () => {
    setIsLoading(true)
    CreateSession(onCompleteSessionCreation, onFailCreateSession)
  }

  const onAddOperation = () => {
    dispatch(toggleModalVisibility('addSessionOperationVisible'))
  }

  const onDeleteSuccess = (item: string) => {
    setSelectedItem(undefined)
    DeleteItemFromSessionStateWithDispatch(dispatch)(item)
    notification['success']({
      message: 'Deleted Operation',
      description: `Successfully deleted operation from session: ${sessionID}`,
    })
    setIsLoading(false)
  }

  const onFailDeleteSessionOperation = (error: any) => {
    // Error toast
    notification['error']({
      message: 'Error Deleting Operation',
      description: `${error}`,
    })
    setIsLoading(false)
  }

  const onDeleteSessionOperation = () => {
    if (selectedItem) {
      setIsLoading(true)
      const request: DeleteOperationFromSessionRequest = {
        sessionID,
        item: selectedItem,
      }

      DeleteOperationFromSession(
        request,
        onDeleteSuccess,
        onFailDeleteSessionOperation
      )
    }
    setIsDeleteSessionOperationVisible(false)
    setIsDetailDrawerVisible(false)
  }

  const rowSelection: TableRowSelection<SessionOperationItem> = {
    onSelect: (selectedRow: SessionOperationItem, isSelected: boolean) => {
      if (isSelected) {
        const rowId: string = rowKeySelector(selectedRow)
        setSelectedItem(rowId)
      } else {
        setSelectedItem(undefined)
      }
    },
    type: 'checkbox',
    hideSelectAll: true,
    selectedRowKeys: selectedItem ? [selectedItem] : [],
  }

  const onViewDetails = () => {
    if (selectedItem) {
      setIsLoading(true)
      // Use the cache if it is in there
      const cachedOp = sessionDetailsCache[selectedItem]
      if (cachedOp) {
        SetSessionSelectedWithDispatch(dispatch)(selectedItem, cachedOp)
        setIsDetailDrawerVisible(true)
      } else {
        const request: GetOperationDetailsRequest = {
          sessionID,
          item: selectedItem,
        }

        GetOperationDetails(request, (op: SessionOperation) => {
          SetSessionSelectedWithDispatch(dispatch)(selectedItem, op)
          setIsDetailDrawerVisible(true)
        })
      }
      setIsLoading(false)
    }
  }

  const onOpenSessionOperationTemplates = () => {
    dispatch(toggleModalVisibility('sessionOperationTemplatesVisible'))
  }

  const footer = () => (
    <Styled.LargeInputWrapper>
      <Button key="1" onClick={onAddOperation} disabled={addDisabled}>
        Add Operation
      </Button>
      <Styled.ButtonWrapper>
        <Button
          key="2"
          onClick={onOpenSessionOperationTemplates}
          disabled={addDisabled}
        >
          Add Operation from Template
        </Button>
      </Styled.ButtonWrapper>

      <Styled.ButtonWrapper>
        <Button key="3" onClick={onViewDetails} disabled={!selectedItem}>
          View Details
        </Button>
      </Styled.ButtonWrapper>
    </Styled.LargeInputWrapper>
  )

  const onDetailClickDelete = () => {
    setIsDeleteSessionOperationVisible(true)
  }

  return (
    <>
      <Styled.Content>
        <Title size={25} level={1}>
          Session Manager
        </Title>
        <Styled.LargeInputWrapper>
          <TextWrapper>
            <Text variant="body">Session ID:</Text>
            <Input
              width="450px"
              value={sessionID}
              onChange={(e) => setSessionID(e.target.value)}
            />
          </TextWrapper>
          <Styled.ButtonWrapper>
            <Button
              disabled={sessionID === ''}
              onClick={() => handleSearchSessionID(sessionID)}
            >
              Search
            </Button>
          </Styled.ButtonWrapper>
          <Styled.ButtonWrapper>
            <Button disabled={sessionID !== ''} onClick={handleCreateSession}>
              Create Session
            </Button>
          </Styled.ButtonWrapper>
        </Styled.LargeInputWrapper>
        <Table
          dataSource={ops}
          columns={columns}
          footer={footer}
          tableLayout={'fixed'}
          rowKey={rowKeySelector}
          rowSelection={rowSelection}
          onRow={(selectedRow, rowIndex) => {
            return {
              onClick: (event) => {
                const rowId: string = rowKeySelector(selectedRow)
                setSelectedItem(rowId)
              }, // click row
              onDoubleClick: (event) => {
                const rowId: string = rowKeySelector(selectedRow)
                setSelectedItem(rowId)
                onViewDetails()
              }, // double click row
            }
          }}
          loading={isLoading}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 600, x: true }}
        />
      </Styled.Content>
      <ConfirmationModal
        description={
          selectedOperationItem
            ? `Are you sure you want to delete "${selectedOperationItem.method}" Operation: "${selectedOperationItem.name}" with ID: ${selectedOperationItem.id}`
            : `Are you sure you want to delete operation from the session?`
        }
        title={`Delete ${selectedOperationItem?.name || `Selected Operation`}`}
        confirmText={'Delete'}
        visible={isDeleteSessionOperationVisible}
        onClose={() => setIsDeleteSessionOperationVisible(false)}
        onTerminate={onDeleteSessionOperation}
      />
      <OperationDetailDrawer
        visible={isDetailDrawerVisible}
        operationName={selectedItem || ''}
        onDelete={onDetailClickDelete}
        onClose={() => {
          setIsDetailDrawerVisible(false)
          ClearSessionSelectedWithDispatch(dispatch)()
        }}
      />
    </>
  )
}

export default SessionManager
