import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const listPatientOrdersAsync: SessionMockSchema = {
  operation: sessionOperations.ListPatientOrdersAsync,
  title: 'Success',
  name: 'ListPatientOrdersAsync',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      patientAlias: {
        tenantId: 'ac47bae3-3c16-4d4c-86b8-15e14b019c34',
        systemId: '207fccec-a88a-4423-8573-dd80030e3f6e',
        externalType: 'patient:id',
        externalId: '1',
      },
      locationAliases: [
        {
          tenantId: 'ac47bae3-3c16-4d4c-86b8-15e14b019c34',
          systemId: '207fccec-a88a-4423-8573-dd80030e3f6e',
          externalType: 'department:id',
          externalId: '195',
        },
      ],
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      orders: [
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199399',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199401',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199402',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199405',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199406',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199407',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199428',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199429',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199431',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199435',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199436',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199437',
            },
            description: 'crutches',
            orderType: 'ORDER',
            orderDate: '2022-08-01T00:00:00Z',
            status: 'CLOSED',
            clinicalProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'clinical_provider:id',
                externalId: '12526653',
              },
              name: 'Ather Mansoor MD',
            },
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Fracture of ankle',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'S82.90XA',
                    display:
                      'Unsp fracture of unsp lower leg, init for clos fx',
                  },
                  {
                    system: 'SNOMED',
                    code: '16114001',
                    display: 'Fracture of ankle',
                  },
                ],
              },
            ],
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199413',
            },
            description: 'adult crutches',
            orderType: 'DME',
            orderDate: '2022-07-28T23:23:00Z',
            status: 'SUBMIT',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Ankle pain',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'M25.579',
                    display:
                      'Pain in unspecified ankle and joints of unspecified foot',
                  },
                  {
                    system: 'SNOMED',
                    code: '247373008',
                    display: 'Ankle pain',
                  },
                ],
              },
            ],
            instructions: 'Use as directed.',
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42810',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199438',
            },
            description: 'adult crutches',
            orderType: 'DME',
            orderDate: '2022-07-28T23:23:00Z',
            status: 'SUBMIT',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Ankle pain',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'M25.579',
                    display:
                      'Pain in unspecified ankle and joints of unspecified foot',
                  },
                  {
                    system: 'SNOMED',
                    code: '247373008',
                    display: 'Ankle pain',
                  },
                ],
              },
            ],
            instructions: 'Use as directed.',
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42810',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '197328',
            },
            description:
              'tetanus,diphtheria toxoid ped (PF) 5 Lf unit-25 Lf unit/0.5 mL IM susp',
            orderType: 'VACCINE',
            orderDate: '2022-07-28T22:19:59Z',
            status: 'ADMINISTER',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42809',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '197329',
            },
            description: 'Pneumovax-23 25 mcg/0.5 mL injection solution',
            orderType: 'VACCINE',
            orderDate: '2022-07-28T23:09:35Z',
            status: 'ADMINISTER',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42813',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199556',
            },
            description:
              'percutaneous transluminal coronary balloon angioplasty, single vessel (SURG)',
            orderType: 'SURGERY',
            orderDate: '2022-07-29T19:22:53Z',
            status: 'CLOSED',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Low back pain',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'M54.50',
                    display: 'Low back pain, unspecified',
                  },
                  {
                    system: 'SNOMED',
                    code: '279039007',
                    display: 'Low back pain',
                  },
                ],
              },
            ],
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42835',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199404',
            },
            description: 'gabapentin 300 mg capsule',
            orderType: 'PRESCRIPTION',
            orderDate: '2022-07-28T19:19:22Z',
            status: 'SUBMIT',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Headache',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'R51.9',
                    display: 'Headache, unspecified',
                  },
                  { system: 'SNOMED', code: '25064002', display: 'Headache' },
                ],
              },
            ],
            instructions: 'Take 1 capsule 3 times a day by oral route.',
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42805',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
        {
          order: {
            externalAlias: {
              tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
              systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
              externalType: 'order:id',
              externalId: '199434',
            },
            description: 'gabapentin 300 mg capsule',
            orderType: 'PRESCRIPTION',
            orderDate: '2022-07-28T19:19:22Z',
            status: 'SUBMIT',
            orderingProvider: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'provider:id',
                externalId: '189',
              },
            },
            orderDiagnoses: [
              {
                description: 'Headache',
                codes: [
                  {
                    system: 'ICD10',
                    code: 'R51.9',
                    display: 'Headache, unspecified',
                  },
                  { system: 'SNOMED', code: '25064002', display: 'Headache' },
                ],
              },
            ],
            instructions: 'Take 1 capsule 3 times a day by oral route.',
            encounter: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'encounter:id',
                externalId: '42805',
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'patient:id',
                externalId: '1',
              },
            },
            location: {
              externalAlias: {
                tenantId: 'da504a21-3d56-48e0-9c74-3cdc00c4d0d2',
                systemId: '4fde38f2-0f83-4ea8-a627-f06705b5598d',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          },
        },
      ],
    },
    null,
    2
  ),
}
