import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const checkinAppointmentSuccess: SessionMockSchema = {
  operation: sessionOperations.CheckinAppointment,
  title: 'Success',
  name: 'CheckinAppointment',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: '77a32736-aa87-4966-9b05-8cb3238ca207',
        systemId: '46362966-2295-490c-beca-8e92d7786945',
        externalType: 'appointment:id',
        externalId: '456',
      },
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      appointmentAlias: {
        tenantId: '77a32736-aa87-4966-9b05-8cb3238ca207',
        systemId: '46362966-2295-490c-beca-8e92d7786945',
        externalType: 'appointment:id',
        externalId: '456',
      },
    },
    null,
    2
  ),
}
