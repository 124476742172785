import { Suspense, useContext, useEffect } from 'react'
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'
import * as Styled from './Styles'
import { Title } from 'tendo-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setAuthToken } from 'store/credentialsReducer'
import TokenManager from './service/TokenManager'
import AuthenticatedApp from 'AuthenticatedApp'

export default function App(): JSX.Element {
  const auth0Context = useContext(Auth0Context)
  if (!auth0Context) {
    // useUser required Auth0Provider (useAuth0)
    throw new Error(`useUser must be used within a Auth0Provider`)
  }

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()
  const dispatch = useAppDispatch()
  const authToken = useAppSelector((state) => state.credentials.authToken)

  useEffect(() => {
    // set token access method ( we'll use this to retrieve tokens in axios interceptor )
    TokenManager.getInstance().onSuccess = (token: string) => {
      dispatch(setAuthToken({ authToken: token }))
    }
  }, [dispatch, setAuthToken])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !authToken) {
      loginWithRedirect()
    }
  }, [isLoading, loginWithRedirect, isAuthenticated])

  useEffect(() => {
    // set token access method ( we'll use this to retrieve tokens in axios interceptor )
    TokenManager.getInstance().getAccessToken = getAccessTokenSilently
  }, [getAccessTokenSilently])

  if (!isLoading && !isAuthenticated && !authToken) {
    return (
      <Suspense fallback="loading">
        <Styled.Wrapper>
          <Title size={35} level={1}>
            Refreshing Auth Token
          </Title>
        </Styled.Wrapper>
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      {!isLoading ? (
        <AuthenticatedApp />
      ) : (
        <Styled.Wrapper>
          <Title size={35} level={1}>
            Loading
          </Title>
        </Styled.Wrapper>
      )}
    </Suspense>
  )
}
