export type OperationMethod = 'POST' | 'GET' | 'PUT' | 'DELETE'

export const OperationMethodList: OperationMethod[] = [
  'POST',
  'GET',
  'PUT',
  'DELETE',
]

export interface SessionOperation {
  // Request details
  request: string
  requestQuery: string
  method: OperationMethod
  requestHeaders: Map<string, string[]>
  // Response details
  response: string
  responseCode: number
  responseContentType: string
  responseHeaders: Map<string, string[]>
}
