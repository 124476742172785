import { configureStore } from '@reduxjs/toolkit'
import { rootReducer, initialState } from './rootReducer'
import { athenaAPI } from '../service/ServiceApi'
import { authTokenExpirationMiddleWare } from '../service/ServiceMiddleware'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildStore = (preloadedState = { ...initialState }) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // Remove redux persist with dates error
      // https://stackoverflow.com/a/63244831
      getDefaultMiddleware({ serializableCheck: false })
        .concat(athenaAPI.middleware)
        .concat(authTokenExpirationMiddleWare),
    preloadedState,
    devTools: true,
  })
}

export const store = buildStore()

export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
