import { SessionMockSchema, sessionOperations } from '../sessionUtils'

export const listProviders: SessionMockSchema = {
  operation: sessionOperations.ListProviders,
  title: 'Success',
  name: 'ListProviders',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      tenantId: '3ba1d8ec-427e-4f8d-8cf2-800f360449dd',
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      providers: [
        {
          externalAlias: {
            tenantId: '3ba1d8ec-427e-4f8d-8cf2-800f360449dd',
            systemId: 'cbac7683-a05b-4837-99ae-986bfff6326b',
            externalType: 'provider:id',
            externalId: '1',
          },
          firstName: 'Marcus',
          lastName: 'Welby',
          fullName: 'Marcus Welby',
          degrees: ['MD'],
          specialties: ['Family Medicine'],
          gender: 'M',
          locations: [
            {
              externalAlias: {
                tenantId: '3ba1d8ec-427e-4f8d-8cf2-800f360449dd',
                systemId: 'cbac7683-a05b-4837-99ae-986bfff6326b',
                externalType: 'department:id',
                externalId: '1',
              },
            },
          ],
          npi: '0',
        },
        {
          externalAlias: {
            tenantId: '3ba1d8ec-427e-4f8d-8cf2-800f360449dd',
            systemId: 'cbac7683-a05b-4837-99ae-986bfff6326b',
            externalType: 'provider:id',
            externalId: '188',
          },
          firstName: 'Test',
          lastName: 'Wallensky',
          fullName: 'DR T WALLENSKY MD',
          degrees: ['MD'],
          specialties: ['Pediatric Internal Medicine'],
          gender: 'F',
          locations: [
            {
              externalAlias: {
                tenantId: '3ba1d8ec-427e-4f8d-8cf2-800f360449dd',
                systemId: 'cbac7683-a05b-4837-99ae-986bfff6326b',
                externalType: 'department:id',
                externalId: '195',
              },
            },
          ],
          npi: '1255928602',
        },
      ],
    },
    null,
    2
  ),
}
