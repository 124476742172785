import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CredentialsState {
  authToken: string
}

export const initialState: CredentialsState = {
  authToken: '',
}

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<{ authToken: string }>) => {
      state.authToken = action.payload.authToken
    },
    resetState: () => initialState,
  },
})

export const { setAuthToken, resetState } = credentialsSlice.actions

export default credentialsSlice.reducer
