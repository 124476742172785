import { ReactNode } from 'react'
import { DrawerProps } from 'tendo-ui'
import * as Styled from '../Styles'

export interface StyledDrawerProps extends DrawerProps {
  visible?: boolean
  children?: string | ReactNode
  onClose?: () => void
  footer?: ReactNode
}

export default function StyledDrawer({
  visible,
  children,
  footer,
  onClose,
}: StyledDrawerProps): JSX.Element {
  return (
    <Styled.DrawerContainer
      closable
      width={650}
      height="auto"
      onClose={onClose}
      placement="right"
      footer={footer}
      visible={visible}
      destroyOnClose
    >
      <Styled.DrawerContent>{children}</Styled.DrawerContent>
    </Styled.DrawerContainer>
  )
}
