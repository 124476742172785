import { Card } from 'antd'
import * as Styled from '../../Styles'
import { Select, SelectOption, Input } from 'tendo-ui'
import { OperationMethod, OperationMethodList } from 'types/sessions'
import { useEffect } from 'react'

interface OperationMockPreviewProps {
  operationName: string
  responseMethod: OperationMethod
  responseCode: number
  request: string
  response: string
  tenantID: string
  systemID: string
  setOperationName: React.Dispatch<React.SetStateAction<string>>
  setOperationMethod: React.Dispatch<React.SetStateAction<OperationMethod>>
  setResponseCode: React.Dispatch<React.SetStateAction<number>>
  setRequest: React.Dispatch<React.SetStateAction<string>>
  setResponse: React.Dispatch<React.SetStateAction<string>>
  setTenantID: React.Dispatch<React.SetStateAction<string>>
  setSystemID: React.Dispatch<React.SetStateAction<string>>
}

const systemIDRegExComma = new RegExp(/"systemId": .+(?=(,))/, 'g')
const tenantIDRegExComma = new RegExp(/"tenantId": .+(?=(,))/, 'g')
const systemIDRegExNewLine = new RegExp(/"systemId": .+(?=(\n))/, 'g')
const tenantIDRegExNewLine = new RegExp(/"tenantId": .+(?=(\n))/, 'g')

export default function OperationMockPreview(
  props: OperationMockPreviewProps
): JSX.Element {
  const {
    systemID,
    tenantID,
    setSystemID,
    setTenantID,
    request,
    response,
    setRequest,
    setResponse,
  } = props
  const onChangeName = (name: string) => {
    // Do not allow special characters
    const specialCharRegex = new RegExp(/^[a-zA-Z0-9]*$/)
    if (specialCharRegex.test(name)) {
      props.setOperationName(name)
    }
  }

  useEffect(() => {
    const newRequest = request
      .replaceAll(systemIDRegExComma, `"systemId": "${systemID}",`)
      .replaceAll(systemIDRegExNewLine, `"systemId": "${systemID}",`)
      .replaceAll(tenantIDRegExComma, `"tenantId": "${tenantID}",`)
      .replaceAll(tenantIDRegExNewLine, `"tenantId": "${tenantID}",`)
    const newResponse = response
      .replaceAll(systemIDRegExComma, `"systemId": "${systemID}",`)
      .replaceAll(systemIDRegExNewLine, `"systemId": "${systemID}",`)
      .replaceAll(tenantIDRegExComma, `"tenantId": "${tenantID}",`)
      .replaceAll(tenantIDRegExNewLine, `"tenantId": "${tenantID}",`)
    setRequest(newRequest)
    setResponse(newResponse)
  }, [request, response, systemID, tenantID])

  return (
    <Card>
      <div>
        <Styled.RowWrapper>
          <Styled.SmallInputWrapper style={{ width: '60%' }}>
            <Styled.InputLabelText>Operation</Styled.InputLabelText>
            <Input
              value={props.operationName}
              type={'text'}
              onChange={(e) => onChangeName(e.currentTarget.value)}
              width={'100%'}
            />
          </Styled.SmallInputWrapper>
          <Styled.RowInputWrapper>
            <Styled.InputLabelText>Response Method</Styled.InputLabelText>
            <Select
              width="100%"
              value={props.responseMethod}
              onChange={(val) =>
                props.setOperationMethod(val as OperationMethod)
              }
            >
              {OperationMethodList.map((method, idx) => (
                <SelectOption key={method + idx.toString()} value={method}>
                  {method}
                </SelectOption>
              ))}
            </Select>
          </Styled.RowInputWrapper>
          <Styled.RowInputWrapper>
            <Styled.InputLabelText>Response Code</Styled.InputLabelText>
            <Input
              value={props.responseCode}
              type={'number'}
              onChange={(e) =>
                props.setResponseCode(Number(e.currentTarget.value))
              }
            />
          </Styled.RowInputWrapper>
        </Styled.RowWrapper>
        <Styled.RowWrapper>
          <Styled.SmallInputWrapper style={{ width: '49%' }}>
            <Styled.InputLabelText>Tenant ID</Styled.InputLabelText>
            <Input
              value={tenantID}
              type={'text'}
              onChange={(e) => setTenantID(e.currentTarget.value)}
              width={'100%'}
            />
          </Styled.SmallInputWrapper>
          <Styled.RowInputWrapper style={{ width: '48%' }}>
            <Styled.InputLabelText>System ID</Styled.InputLabelText>
            <Input
              value={systemID}
              type={'text'}
              onChange={(e) => setSystemID(e.currentTarget.value)}
              width={'100%'}
            />
          </Styled.RowInputWrapper>
        </Styled.RowWrapper>
        <Styled.SmallInputWrapper>
          <Styled.InputLabelText>Request</Styled.InputLabelText>
          <Styled.JSONInput
            value={request}
            onChange={(e) => setRequest(e.target.value)}
          ></Styled.JSONInput>
        </Styled.SmallInputWrapper>
        <Styled.SmallInputWrapper>
          <Styled.InputLabelText>Response</Styled.InputLabelText>
          <Styled.JSONInput
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />
        </Styled.SmallInputWrapper>
      </div>
    </Card>
  )
}
