import { AxiosRequestConfig } from 'axios' /* eslint-disable @typescript-eslint/ban-types */
import { baseAxios, errorHandler } from './ServiceApi'
import { SessionOperation } from 'types/sessions'

const REVERSE_PROXY_BASE_URL = '/sessions'

export function CreateSession(
  onComplete: (response: string) => void,
  onFailure?: (error: any) => void
): void {
  const requestConfig: AxiosRequestConfig<FormData> = {
    headers: { 'Content-Type': 'application/json' },
  }

  const data = new URLSearchParams()
  baseAxios
    .post(REVERSE_PROXY_BASE_URL, data, requestConfig)
    .then((response) => {
      if (response?.data?.sessionID) {
        onComplete(response.data.sessionID)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure(err)
      }
    })
}

export interface GetSessionOperationsResponse {
  items: string[]
}

export function GetSessionOperations(
  sessionID: string,
  onComplete: (response: GetSessionOperationsResponse) => void,
  onFailure?: () => void
): void {
  const url = `${REVERSE_PROXY_BASE_URL}/${sessionID}/operations`

  baseAxios
    .get<GetSessionOperationsResponse>(url)
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

export interface AddSessionOperationRequest {
  sessionID: string
  operationName: string
  operation: SessionOperation
}

export interface AddSessionOperationResponse {
  success: string
  item: string
}

export function AddOperationToSession(
  request: AddSessionOperationRequest,
  onSuccess: (item: string) => void,
  onFailure?: () => void
): void {
  const url = `${REVERSE_PROXY_BASE_URL}/${request.sessionID}/${request.operationName}/define`

  baseAxios
    .post<AddSessionOperationResponse>(url, request.operation)
    .then((response) => {
      if (response && response.data) {
        onSuccess(response.data.item)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

export interface DeleteOperationFromSessionRequest {
  sessionID: string
  item: string
}

export interface DeleteOperationFromSessionResponse {
  success: string
}

export function DeleteOperationFromSession(
  request: DeleteOperationFromSessionRequest,
  onSuccess?: (item: string) => void,
  onFailure?: (error: any) => void
): void {
  const url = `${REVERSE_PROXY_BASE_URL}/${request.sessionID}/operations`

  const params = {
    item: request.item,
  }

  baseAxios
    .delete<DeleteOperationFromSessionResponse>(url, { params })
    .then(() => {
      if (onSuccess) {
        onSuccess(request.item)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure(err)
      }
    })
}

export interface GetOperationDetailsRequest {
  sessionID: string
  item: string
}

export function GetOperationDetails(
  request: GetOperationDetailsRequest,
  onComplete: (response: SessionOperation) => void,
  onFailure?: () => void
): void {
  const url = `${REVERSE_PROXY_BASE_URL}/${request.sessionID}/operations`

  const params = {
    item: request.item,
  }

  baseAxios
    .get<SessionOperation>(url, { params })
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}
