import { Divider as AntDivider, DividerProps as AntDividerProps } from 'antd'
import styled from 'styled-components'

interface DividerProps extends AntDividerProps {
  gap?: number
}
const TendoDivider = ({ ...props }: DividerProps) => <AntDivider {...props} />

const Divider = styled(TendoDivider)`
  && {
    border-top: 2px solid ${({ theme }) => theme.colors.space200};
    margin: ${({ gap }) => (gap ? `${gap}px` : '16px')} 0px;
  }
`

export default Divider
