import { useState } from 'react'
import { Menu } from 'antd'
import * as Styled from './Styles'
import Patients from 'components/Patients'
import Appointments from 'components/Appointments'
import Departments from 'components/Departments'
import Providers from 'components/Providers'
import Encounters from 'components/Encounters'
import QuestionnaireResponses from 'components/QuestionnaireResponse'
import Slots from 'components/Slots'
import { Text } from 'tendo-ui'
import CreateSlotsModal from 'components/CreateSlotsModal'
import { useAppDispatch } from 'store/hooks'
import * as API from './service/ServiceApi'
import {
  SetAppointmentsWithDispatch,
  SetDepartmentsWithDispatch,
  SetEncountersWithDispatch,
  SetPatientsWithDispatch,
  SetProvidersWithDispatch,
} from 'store/resourceReducer'
import { useLazyGetAthenaSlotsQuery } from './service/ServiceApi'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

type ResourceKey =
  | 'patients'
  | 'appointments'
  | 'departments'
  | 'providers'
  | 'encounters'
  | 'questionnaireResponses'
  | 'slots'

type ResourceDescription = [ResourceKey, string]

const resources: ResourceDescription[] = [
  ['patients', 'Patients'],
  ['appointments', 'Appointments'],
  ['departments', 'Departments'],
  ['providers', 'Providers'],
  ['encounters', 'Encounters'],
  ['questionnaireResponses', 'QuestionnaireResponses'],
  ['slots', 'Slots'],
]

export const ResourceDisplay = (): JSX.Element => {
  const [resourceKey, setResourceKey] = useState<ResourceKey>('patients')
  const dispatch = useAppDispatch()

  const onClickItemBuilder = (key: ResourceKey) => () => setResourceKey(key)
  const [refetchSlots] = useLazyGetAthenaSlotsQuery()

  const loadStore = () => {
    API.GetAthenaDepartments(SetDepartmentsWithDispatch(dispatch))
    API.GetAthenaAppointments(SetAppointmentsWithDispatch(dispatch))
    API.GetAthenaEncounters(SetEncountersWithDispatch(dispatch))
    API.GetAthenaPatients(SetPatientsWithDispatch(dispatch))
    refetchSlots('')
    API.GetKyruusProviders(SetProvidersWithDispatch(dispatch))
  }

  const getContent = () => {
    switch (resourceKey) {
      case 'patients':
        return <Patients />
      case 'appointments':
        return <Appointments />
      case 'departments':
        return <Departments />
      case 'providers':
        return <Providers />
      case 'encounters':
        return <Encounters />
      case 'questionnaireResponses':
        return <QuestionnaireResponses />
      case 'slots':
        return (
          <>
            <Slots />
            <CreateSlotsModal key={'slots'} destroyOnClose={true} />
          </>
        )
    }
  }

  const items: ItemType[] = resources.map((resourceDesc) => {
    return {
      label: resourceDesc[1],
      key: resourceDesc[0],
      onClick: onClickItemBuilder(resourceDesc[0]),
    }
  })
  items.push({
    style: { marginLeft: 'auto' },
    key: 'reload',
    onClick: loadStore,
    label: (
      <Text variant="body" style={{ paddingTop: 10 }}>
        Reload Store
      </Text>
    ),
  })

  return (
    <Styled.Wrapper>
      <Menu selectedKeys={[resourceKey]} mode="horizontal" items={items} />
      <div>{getContent()}</div>
    </Styled.Wrapper>
  )
}
