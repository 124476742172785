import { AthenaV1Encounter, ResourceType } from 'types/types'
import { DefaultRenderText } from './ResourceTable'
import { useState } from 'react'
import AthenaResourceTable from './AthenaResourceTable'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { SetEncountersWithDispatch } from 'store/resourceReducer'
import { GetAthenaEncounters } from 'service/ServiceApi'

const columns = [
  {
    title: 'ID',
    dataIndex: 'EncounterID',
    key: 'EncounterID',
    render: DefaultRenderText,
    sorter: (a: ResourceType, b: ResourceType) =>
      (a as AthenaV1Encounter).EncounterID -
      (b as AthenaV1Encounter).EncounterID,
  },
  {
    title: 'Appointment ID',
    dataIndex: 'AppointmentID',
    key: 'AppointmentID',
    render: DefaultRenderText,
  },
  {
    title: 'Department ID',
    dataIndex: 'DepartmentID',
    key: 'DepartmentID',
    render: DefaultRenderText,
  },
  {
    title: 'Encounter Type',
    dataIndex: 'EncounterType',
    key: 'EncounterType',
    render: DefaultRenderText,
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    render: DefaultRenderText,
  },
  {
    title: 'Encounter Date',
    dataIndex: 'EncounterDate',
    key: 'EncounterDate',
    render: DefaultRenderText,
  },
  {
    title: 'Encounter Visit Name',
    dataIndex: 'EncounterVisitName',
    key: 'EncounterVisitName',
    render: DefaultRenderText,
  },
]

const EncounterIDSelector = (encounter: AthenaV1Encounter) =>
  encounter.EncounterID.toString()

const Encounters = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const encounters = useAppSelector((state) => state.resources.encounters)

  const onComplete = (encounters: AthenaV1Encounter[]) => {
    SetEncountersWithDispatch(dispatch)(encounters)
    setIsLoading(false)
  }

  const refreshEncounters = () => {
    setIsLoading(true)
    GetAthenaEncounters(onComplete, () => setIsLoading(false))
  }

  return (
    <AthenaResourceTable
      title={'Encounters'}
      dataSource={encounters}
      columns={columns}
      loading={isLoading}
      resourceType={'encounters'}
      idSelector={EncounterIDSelector}
      refreshResource={refreshEncounters}
    />
  )
}

export default Encounters
