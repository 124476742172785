import {
  SessionMockSchema,
  sessionOperations,
} from 'sessionTemplates/sessionUtils'

export const listPatientEncountersAsyncSuccess: SessionMockSchema = {
  operation: sessionOperations.ListPatientEncountersAsync,
  title: 'Success',
  name: 'ListPatientEncountersAsync',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      patientAlias: {
        tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
        systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
        externalType: 'patient:id',
        externalId: '26665',
      },
      locationAliases: [
        {
          tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
          systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
          externalType: 'department:id',
          externalId: '195',
        },
      ],
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      links: [
        {
          encounter: {
            externalAlias: {
              tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
              systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
              externalType: 'encounter:id',
              externalId: '42813',
            },
            patient: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'patient:id',
                externalId: '26665',
              },
            },
          },
        },
        {
          appointment: {
            externalAlias: {
              tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
              systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
              externalType: 'appointment:id',
              externalId: '1208991',
            },
            status: 'Fulfilled',
            appointmentType: 'FOLLOW UP 30',
            slot: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'appointment:id',
                externalId: '1208991',
              },
              startTime: '2022-04-12T13:00:00Z',
              endTime: '2022-04-12T13:30:00Z',
              provider: {
                externalAlias: {
                  tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                  systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                  externalType: 'provider:id',
                  externalId: '111',
                },
              },
              location: {
                externalAlias: {
                  tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                  systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                  externalType: 'department:id',
                  externalId: '1',
                },
              },
            },
            encounter: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'encounter:id',
                externalId: '42470',
              },
              patient: {
                externalAlias: {
                  tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                  systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                  externalType: 'patient:id',
                  externalId: '26509',
                },
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'patient:id',
                externalId: '26509',
              },
            },
            appointmentTypeId: '1186',
          },
          encounter: {
            externalAlias: {
              tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
              systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
              externalType: 'encounter:id',
              externalId: '42470',
            },
            patient: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'patient:id',
                externalId: '26509',
              },
            },
          },
        },
        {
          appointment: {
            externalAlias: {
              tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
              systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
              externalType: 'appointment:id',
              externalId: '1231894',
            },
            status: 'Booked',
            appointmentType: 'ESTABLISHED PATIENT 30',
            slot: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'appointment:id',
                externalId: '1231894',
              },
              startTime: '2024-08-19T12:00:00Z',
              endTime: '2024-08-19T12:30:00Z',
              provider: {
                externalAlias: {
                  tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                  systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                  externalType: 'provider:id',
                  externalId: '191',
                },
              },
              location: {
                externalAlias: {
                  tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                  systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                  externalType: 'department:id',
                  externalId: '1',
                },
              },
            },
            patient: {
              externalAlias: {
                tenantId: 'ea65377c-16a5-4c47-ae03-1651b8822cfc',
                systemId: 'fdde567f-1ffa-437a-9ae1-f097a5e96528',
                externalType: 'patient:id',
                externalId: '26735',
              },
            },
            appointmentTypeId: '1189',
          },
        },
      ],
    },
    null,
    2
  ),
}
