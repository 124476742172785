import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddSessionOperationRequest } from 'service/SessionService'
import { SessionOperation } from 'types/sessions'
import { AppDispatch } from './store'

interface SessionState {
  sessionID: string
  items: string[]
  selectedSession?: SessionOperation
  sessionCache: { [key: string]: SessionOperation } //Map<string, SessionOperation>
}

export const initialState: SessionState = {
  sessionID: '',
  items: [],
  selectedSession: undefined,
  sessionCache: {},
}

type SessionDefinition = Omit<SessionState, 'selectedSession' | 'sessionCache'>

interface SessionOperationPayload {
  item: string
  op: SessionOperation
}

interface AddItemToSessionPayload {
  request: AddSessionOperationRequest
  item: string
}

export const sessionSlice = createSlice({
  name: 'sessions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSessionDefinition: (state, action: PayloadAction<SessionDefinition>) => {
      state.items = action.payload.items
      state.sessionID = action.payload.sessionID
      // Reset the cache
      state.sessionCache = {}
    },
    setSessionID: (state, action: PayloadAction<string>) => {
      state.sessionID = action.payload
      // Reset the cache
      state.sessionCache = {}
    },
    addItemToSession: (
      state,
      action: PayloadAction<AddItemToSessionPayload>
    ) => {
      const newCache = { ...state.sessionCache }
      newCache[action.payload.item] = action.payload.request.operation
      const newItems = [...state.items, action.payload.item]
      state.sessionCache = newCache
      state.items = newItems
    },
    deleteItemFromSession: (state, action: PayloadAction<string>) => {
      const newItems = state.items.filter((i) => i !== action.payload)
      state.items = newItems
      state.selectedSession = undefined
    },
    setSelectedSession: (
      state,
      action: PayloadAction<SessionOperationPayload>
    ) => {
      const selectedOp = action.payload.op
      // Cache the operation if it isn't cached already. They are immutable.
      const newCache = { ...state.sessionCache }
      newCache[action.payload.item] = selectedOp
      state.sessionCache = newCache
      state.selectedSession = selectedOp
    },
    clearSelectedSession: (state) => {
      state.selectedSession = undefined
    },
    resetState: () => initialState,
  },
})

export const {
  setSessionDefinition,
  resetState,
  addItemToSession,
  deleteItemFromSession,
  setSelectedSession,
  clearSelectedSession,
} = sessionSlice.actions

type SetSessionDefinitionWithDispatchReturnType = (
  state: SessionDefinition
) => void

export const SetSessionDefinitionWithDispatch = (
  dispatch: AppDispatch
): SetSessionDefinitionWithDispatchReturnType => {
  return (state: SessionDefinition) => dispatch(setSessionDefinition(state))
}

type SetSessionSelectedWithDispatchReturnType = (
  item: string,
  op: SessionOperation
) => void

export const SetSessionSelectedWithDispatch = (
  dispatch: AppDispatch
): SetSessionSelectedWithDispatchReturnType => {
  return (item: string, op: SessionOperation) =>
    dispatch(setSelectedSession({ item, op }))
}

export const ClearSessionSelectedWithDispatch = (dispatch: AppDispatch) => {
  return () => dispatch(clearSelectedSession())
}

type AddItemToSessionStateWithDispatchReturnType = (
  item: string,
  request: AddSessionOperationRequest
) => void

export const AddItemToSessionStateWithDispatch = (
  dispatch: AppDispatch
): AddItemToSessionStateWithDispatchReturnType => {
  return (item: string, request: AddSessionOperationRequest) =>
    dispatch(addItemToSession({ item, request }))
}

type DeleteItemFromSessionStateWithDispatchReturnType = (item: string) => void

export const DeleteItemFromSessionStateWithDispatch = (
  dispatch: AppDispatch
): DeleteItemFromSessionStateWithDispatchReturnType => {
  return (item: string) => dispatch(deleteItemFromSession(item))
}

export default sessionSlice.reducer
