import { SessionMockSchema, sessionOperations } from '../sessionUtils'

export const listLocations: SessionMockSchema = {
  operation: sessionOperations.ListLocations,
  title: 'Success',
  name: 'ListLocations',
  responseMethod: 'POST',
  responseCode: 200,
  request: JSON.stringify(
    {
      tenantId: 'test_tenant',
    },
    null,
    2
  ),
  response: JSON.stringify(
    {
      locations: [
        {
          externalAlias: {
            tenantId: '96943cd3-a659-4b99-8cfb-ab9fdb4d02fe',
            systemId: '72e18b0d-c7c0-4f0c-8cbb-b29f7e3ee09e',
            externalType: 'department:id',
            externalId: '1',
          },
          address: {
            line1: '501 Broad Street',
            line2: 'Apt 4',
            city: 'Rome',
            state: 'GA',
            zip: '30161-3096',
          },
          name: 'Testy Test Office',
          phone: '(123) 456-7899',
          longitude: '-85.17026',
          latitude: '34.25597',
          timezone: 'America/New_York',
        },
        {
          externalAlias: {
            tenantId: '96943cd3-a659-4b99-8cfb-ab9fdb4d02fe',
            systemId: '72e18b0d-c7c0-4f0c-8cbb-b29f7e3ee09e',
            externalType: 'department:id',
            externalId: '21',
          },
          address: {
            line1: '1 NORTH ERWIN STREET',
            city: 'CARTERSVILLE',
            state: 'GA',
            zip: '30120-3121',
          },
          name: 'Cartersville Office-',
          longitude: '-84.79669',
          latitude: '34.1649',
          timezone: 'America/New_York',
        },
      ],
    },
    null,
    2
  ),
}
